<template lang="pug">
  v-expansion-panel#cost-panel
    v-expansion-panel-header
      template(v-slot:default='{ open }')
        v-row.my-0(no-gutters align='center')
          v-col.text-h6(cols='4') Cost
    v-expansion-panel-content(eager ref='cost-panel-content')
      fees.media-fees(
        type='Percentage'
        label='Media'
        suffix='%'
        :fees='mediaFees'
        :isVisible='true'
        :refreshKey='refreshKey'
        @addFee='addFee'
        @deleteFee='deleteFee'
        @updateValue='updateValue'
        @saveFeesInStore='saveFeesInStore'
        @updateLabel='updateLabel'
      )
      fees.cpm-fees(
        type='Cpm'
        label='Cpm'
        :suffix='currencySymbol'
        :fees='cpmFees'
        :isVisible='canSeeNewAuctionPackageCpmFee'
        :refreshKey='refreshKey'
        @addFee='addFee'
        @deleteFee='deleteFee'
        @updateValue='updateValue'
        @saveFeesInStore='saveFeesInStore'
        @updateLabel='updateLabel'
      )
</template>

<script>
import helpers from '@/services/helpers.service'
import flags from '@/plugins/rox/flags'
import defaultCurrencyValues from '@/services/defaultCurrencyValues'
import fees from '@/components/auctionPackages/fees.vue'

export default {
  components: {
    fees
  },
  props: {
    existingFees: {
      type: Array
    }
  },
  data () {
    return {
      rules: {
        positive: v => v >= 0 || 'Positive numbers only'
      },
      defaultFees: [{
        type: 'Percentage',
        label: 'Broadsign Ads Fee',
        layoutOrder: 1,
        value: 5
      }],
      fees: [],
      refreshKey: 1,
      maximumNumberOfFees: 5
    }
  },
  created () {
    if (this.canSeeNewAuctionPackageCpmFee) {
      this.defaultFees.push({
        type: 'Cpm',
        label: 'Broadsign Ads Fee',
        layoutOrder: 1,
        value: 0
      })
    }

    this.fees = !this.existingFees
      ? JSON.parse(JSON.stringify(this.defaultFees))
      : JSON.parse(JSON.stringify(this.existingFees.map(ef => { return { ...ef, value: ef.type === 'Cpm' ? ef.value : 100 * ef.value } })))

    this.saveFeesInStore()
  },
  computed: {
    mediaFees () {
      // eslint-disable-next-line no-unused-expressions
      this.refreshKey
      return this.fees
        .filter(f => f.type === 'Percentage')
        .sort((a, b) => a.layoutOrder - b.layoutOrder)
        .map(f => {
          return {
            ...f,
            displayedValue: f.value || null,
            secondaryLabel: `Media Fee ${f.layoutOrder}`,
            isLabelFixed: f.layoutOrder === 1,
            cssClass: `media-fee-${f.layoutOrder}`
          }
        })
    },
    cpmFees () {
      // eslint-disable-next-line no-unused-expressions
      this.refreshKey
      return this.fees
        .filter(f => f.type === 'Cpm')
        .sort((a, b) => a.layoutOrder - b.layoutOrder)
        .map(f => {
          return {
            ...f,
            displayedValue: f.value || null,
            secondaryLabel: `CPM Fee ${f.layoutOrder}`,
            isLabelFixed: f.layoutOrder === 1,
            cssClass: `cpm-fee-${f.layoutOrder}`
          }
        })
    },
    canSeeNewAuctionPackageCpmFee () {
      return flags.canSeeNewAuctionPackageCpmFee.isEnabled()
    },
    currencySymbol () {
      const audienceCurrencyCode = this.$store.getters['audience/getCurrency']?.code
      return audienceCurrencyCode ? defaultCurrencyValues(audienceCurrencyCode).currencySymbolString : '$'
    }
  },
  methods: {
    saveFeesInStore () {
      const formattedFees = this.formatFeesForApi(this.fees.filter(f => f.value == null || f.value >= 0))
      this.$store.commit('auctionPackage/setFees', formattedFees)
    },
    updateValue (position, type, e) {
      const value = e.target.value >= 0 ? e.target.value : 0
      const index = this.fees.findIndex(f => f.type === type && f.layoutOrder === position)
      this.fees[index].value = helpers.trimNumber(value, 2)
      this.saveFeesInStore()
      this.refreshKey++
    },
    updateLabel (position, type, e) {
      const index = this.fees.findIndex(f => f.type === type && f.layoutOrder === position)
      const label = e.target.value

      this.fees[index].label = label
      this.saveFeesInStore()
    },
    addFee (type) {
      var typedFees = this.fees.filter(f => f.type === type)
      if (typedFees?.length >= this.maximumNumberOfFees) return

      if (!typedFees?.length) {
        this.fees.push(...JSON.parse(JSON.stringify(this.defaultFees.filter(f => f.type === type))))
        this.saveFeesInStore()
        return
      }

      this.fees.push({
        type,
        label: '',
        layoutOrder: this.deduceNextFeePosition(typedFees),
        value: null
      })
    },
    deleteFee (position, type) {
      const index = this.fees.findIndex(f => f.type === type && f.layoutOrder === position)
      this.fees.splice(index, 1)
      this.saveFeesInStore()
    },
    formatFeesForApi (fees) {
      return fees.map(f => {
        if (f.type === 'Cpm') {
          return {
            ...f,
            value: f.value ? helpers.trimNumber(f.value, 2) : 0
          }
        }
        return {
          layoutOrder: f.layoutOrder,
          type: f.type,
          label: f.label,
          value: f.value ? helpers.trimNumber(f.value / 100, 4) : 0
        }
      })
    },
    deduceNextFeePosition (fees) {
      const takenPositions = fees.map(f => f.layoutOrder)

      for (let i = 1; i <= this.maximumNumberOfFees; i++) {
        if (!takenPositions.includes(i)) {
          return i
        }
      }
    }
  }
}
</script>
