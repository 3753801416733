<template lang="pug">
div
  vue-headful(:title="componentConfig.branding.title('User Profile')")
  v-container(fluid)
    v-row.my-0(justify="center")
      v-col(md=8 xl=6)
        userProfile(:userToBeEdited="fetchedUser" :loading="loading")
    v-row.my-0(justify="center")
      v-col(md=8 xl=6)
        userChangePassword(v-if="isMyProfile")
    v-row.my-0(justify="center")
      v-col(md=8 xl=6)
        userNotifications(v-if="isMyProfile")
</template>

<script>
import userProfile from '@/components/user/userProfile.vue'
import userChangePassword from '@/components/user/userChangePassword.vue'
import userNotifications from '@/components/user/userNotifications.vue'
import componentConfigService from '@/services/componentConfig'

import userApi from '@/services/user.api'

export default {
  components: {
    userProfile,
    userChangePassword,
    userNotifications
  },
  data () {
    return {
      loading: false,
      fetchedUser: null
    }
  },
  created () {
    this.getUser()
  },
  watch: {
    userId (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getUser()
      }
    }
  },
  computed: {
    userId () {
      return this.$route.params.userId
    },
    can () {
      const userPerms = this.$store.getters['user/permissions']('user')
      return {
        update: {
          user: userPerms.update.default
        }
      }
    },
    isMyProfile () {
      const profile = this.$store.getters['user/getProfile']
      return (this.userId && (profile && profile.id)) && parseInt(this.userId) === profile.id
    },
    titleText () {
      if (this.userId === 'add') return 'Invite user'
      else {
        return this.fetchedUser ? this.fetchedUser.firstName + ' ' + this.fetchedUser.lastName : 'Loading...'
      }
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    getUser () {
      this.loading = true

      if (this.userId === 'add') {
        if (!this.can.update.user) this.$router.push({ name: 'Access Denied' })
        else {
          this.fetchedUser = null
          this.loading = false
        }
      } else {
        if (this.can.update.user || (!this.can.update.user && this.isMyProfile)) {
          userApi.getUsers({ take: 1, filters: ['id eq ' + this.userId] })
            .then(res => {
              if (res.length) {
                this.fetchedUser = res[0]
                this.loading = false
              } else {
                // User not found, redirect to all users
                this.$router.push({ name: 'Access Denied' })
              }
            })
            .catch(() => { this.$router.push({ name: 'Access Denied' }) })
        } else {
          this.$router.push({ name: 'Access Denied' })
        }
      }
    }
  }
}
</script>
