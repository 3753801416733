<template lang="pug">
  div
    vue-headful#audience-title(:title="componentConfig.branding.title(headTitle)")
    v-container.pa-0(fluid grid-list-lg text-left)
      v-scroll-y-transition
        v-btn.primary--text.mx-auto.mb-4(
          style='position: fixed; bottom:0; left: 0; right: 0; z-index: 2;'
          color='white'
          :width="!showMapOnly? 95 : 150"
          rounded
          @click='toggleView'
          v-if='$vuetify.breakpoint.xsOnly'
          elevation=12)
          v-icon.mr-2(color='primary') {{ showMapOnly? 'mdi-menu' : 'mdi-map-outline' }}
          | {{ showMapOnly? 'inventory' : 'map' }}

      v-row.ma-0(style='background-color:white;')
        v-col.pa-0.pa-sm-3(cols='12' md=6 v-show="!showMapOnly || $vuetify.breakpoint.smAndUp")
          v-container.py-0(fluid)
            v-row.my-0
              v-col
                v-skeleton-loader(v-if='!details' type='image' height=60 width='50%')
                .d-flex(v-if='details')
                  #audience-name.display-1.mr-2 {{ details.name }}
                  v-btn#edit-audience(v-if='!isOnAuctionPackagePage' icon @click='openEditAudienceDialog')
                    v-icon mdi-pencil
            v-skeleton-loader(v-if='!details && isOnAuctionPackagePage && (!isPublic || can.create.shareLink)' type='image' height=40 width='100%')
            v-row#view-auction-package-header.my-0.align-center(v-if='isOnAuctionPackagePage && details && (!isPublic || hasStartAndEndDate)' style='position:relative; z-index:3;')
              v-toolbar.my-2.pl-0(flat dense color='transparent' style='border-top: 1px solid rgba(0,0,0,.12) !important; border-bottom: 1px solid rgba(0,0,0,.12) !important;')
                  v-switch.view-auction-package-switch.xs-small.py-0.my-0.ml-0(v-if='!isPublic || can.create.shareLink' :input-value="details.status=='Active'" hide-details color='primary' :disabled="isStatusLoading || details.status == 'Archived'" @change="updateStatus()")
                  v-chip.text-uppercase.mr-4(v-if='!isPublic || can.create.shareLink' :color="statusColor" small outlined)
                    v-icon.mr-1.ml-0(:color="statusColor"  small) mdi-circle
                    small#view-auction-package-status.text--primary(style='white-space: nowrap;') {{ details.status }}
                  .caption#auction-package-schedule(v-if='hasStartAndEndDate')
                    strong Schedule:
                    | &nbsp; {{ this.formatsApDates(this.details.startDate) }} - {{ this.formatsApDates(this.details.endDate) }}
                  v-btn#share-btn(v-if='!isPublic || can.create.shareLink' color="primary" text @click="generateShareToken")
                      |  Share
                  v-btn#edit-auction-package(v-if='!isPublic || can.create.shareLink' icon @click='openEditAuctionPackageDialog')
                    v-icon mdi-pencil
                  v-tooltip(v-if='!isPublic || can.create.shareLink' top)
                    template(v-slot:activator='{ on, attrs }')
                      v-btn.troubleshooting-tool-opener-btn(v-if='isOnAuctionPackagePage' icon v-bind='attrs' v-on='on' @click.stop='troubleshootingToolOpen = true')
                        v-icon mdi-wrench
                    span.caption Troubleshoot auction package
                  v-tooltip(v-if='!isPublic || can.create.shareLink' top)
                    template(v-slot:activator='{ on }')
                      v-btn#download-csv-button(v-on='on' icon @click='exportCsv')
                        v-icon mdi-download
                    div.text-caption Export auction package in CSV
                  .action-menu-wrapper(v-if='!isPublic || can.create.shareLink')
                    v-menu(v-if='isOnAuctionPackagePage' offset-y :close-on-content-click="false" v-model="actionsMenuOpen" :attach='true')
                      template(v-slot:activator='{ on }')
                        v-btn#more-menu(icon v-on='on' @click.stop='')
                          v-icon mdi-dots-vertical
                      v-list(dense)
                        confirmationDialog.duplicate-auction-package-confirmation(
                          @confirmed="duplicateAuctionPackage"
                          ref="confirmationDialogDuplicate"
                          :details="{title: `Duplicate Auction Package`, msg: `Are you sure you want to duplicate this auction package?`, btnLabel: `Duplicate Auction Package`}"
                          :confirmDisabled="duplicateConfirmDisabled"
                          )
                          template(v-slot:customActivator='')
                            v-list-item#duplicate-btn(@click="$root.$emit('openConfirmDialog', `Duplicate Auction Package`)")
                              v-list-item-icon.mr-4
                                v-icon mdi-content-copy
                              v-list-item-title Duplicate
                          template(v-slot:customOptions='')
                            orgPicker.mt-4(
                              ref='orgPicker'
                              :onChange='onOrgPickerChange'
                              sameOrgLabel='Keep in the same organization'
                              diffOrgLabel='Duplicate in a different organization'
                              :onlyDspPartners='true'
                              )
                        confirmationDialog.archive-plan-confirmation(
                          @confirmed="archiveAuctionPackage"
                          ref="confirmationDialogArchive"
                          :details="{title: `Archive Auction Package`, msg: `Are you sure you want to archive this auction package?`, btnLabel: `Archive auction package`}"
                          )
                          template(v-slot:customActivator='')
                            v-list-item#archive-btn(v-if='details.status != "Archived"' @click="$root.$emit('openConfirmDialog', `Archive Auction Package`)")
                              v-list-item-icon.mr-4
                                v-icon mdi-delete
                              v-list-item-title Archive

            v-row.my-0
              v-col
                v-row.my-0(:justify='$vuetify.breakpoint.smAndDown ? "space-between" : "start"' no-gutters)
                  v-card#view-auction-package-code.py-2.mr-4(v-if='isOnAuctionPackagePage' flat min-width=80)
                    .stats
                      .overline DEAL ID
                      .d-flex.align-center
                        v-skeleton-loader(v-if='!details && isOnAuctionPackagePage' type='image' height=40 width='100%')
                        .text-h6(v-if='details') {{ details.code }}
                  v-card#view-audience-impressions.py-2.mr-8(flat)
                    v-menu(top offset-y open-on-hover max-width='450' open-delay='250')
                      template(v-slot:activator='{on}')
                        .stats(v-on='on')
                          .overline Weekly Imps.
                          .d-flex.align-center
                            v-icon.text--secondary.mr-1 mdi-account-multiple
                            .text-h6 {{ impressionsNum }}
                      v-card
                        v-card-text.primary--text.caption
                          p
                            | An
                            strong &nbsp; impression
                            |  is someone exposed to an advertising creative.
                          p
                            | The
                            strong &nbsp; weekly impressions
                            | &nbsp; is the total number available impressions over a week. The measurement is based on available impressions sent by media owners over the past week and is continually updated to improve forecasting accuracy.
                  v-card#view-audience-targeted-venues.py-2.mr-4(flat min-width=80)
                    v-menu(top offset-y open-on-hover max-width='450' open-delay='250')
                      template(v-slot:activator='{on}')
                        .stats(v-on='on')
                          .overline Venues
                          .d-flex.align-center
                            v-icon.text--secondary.ml-n1 mdi-map-marker
                            .text-h6 {{ venuesNum }}
                      v-card
                        v-card-text.secondary--text
                            p
                              | A
                              strong &nbsp; venue
                              | &nbsp; is the physical place where screens are located.
                            p This number represents the total number of venues currently available in this package.

                  v-card#view-audience-screens.py-2.mr-4(flat min-width=80)
                    .stats
                      .overline Screens
                      .d-flex.align-center
                        v-icon.text--secondary.mr-1 mdi-television
                        .text-h6 {{ screensNum }}

                  v-card#view-audience-bid-range.py-2.mr-8(flat v-if='!isLoading')
                    .stats
                      .overline Price Range in CPM
                      v-chip.white--text.font-weight-bold(color='amber darken-1') {{ bidRange }}

            v-row#view-audience-targeted-impressions.my-0
              v-col(cols=12 xl=6)
                v-row.my-0
                  v-col.py-0(cols=12 :order='$vuetify.breakpoint.smAndDown ? 2 : 1')
                    div
                      .text-overline Est. Impressions Distribution
                      impressionsDistribution.my-2(
                          :environments='forecast.environments'
                          :taxonomy='exchangeTaxonomy'
                          :initialized="!forecastLoading"
                          :loading="false"
                          :style="`height: 100px; width: ${$vuetify.breakpoint.xsOnly ? 100 : 55}%;`"
                        )

              v-col(cols='12' xl=6 v-if='!isLoading')
                div#view-audience-location.mb-2.targeting-summary.location(v-if='visibleSections.location')
                  .text-overline Location
                  .one-line-truncate
                    .text-body-2.text-overflow-hidden(v-if='!isLoading' v-for='item in targetingSummary.location.listToDisplay') {{ item }}
                    v-skeleton-loader.mt-2(v-if='isLoading' type='text' width='40%')
                  v-dialog(v-if='!isLoading && targetingSummary.location.hidden.length > 0' max-width=550 :fullscreen='$vuetify.breakpoint.xsOnly' v-model='moreLocationsDialogOpen')
                    template(v-slot:activator='{on}')
                      .primary--text.text-caption(v-on='on' style='cursor: pointer;') {{ `+ ${targetingSummary.location.hidden.length} more` }}
                    v-card(:tile='$vuetify.breakpoint.xsOnly')
                      v-card-title
                        | Location
                        v-spacer
                        v-btn(icon @click='moreLocationsDialogOpen = false')
                          v-icon mdi-close
                      v-list-item-group(color='primary')
                        v-list-item.px-6(
                          v-for='(item, i) in [...targetingSummary.location.listToDisplay, ...targetingSummary.location.hidden]'
                          :key='i' dense)
                          | {{ item }}
                div#view-audience-environment.mb-2.targeting-summary.environment(v-if='visibleSections.environment')
                  .text-overline Environment
                  .two-lines-truncate.text-body-2.text-overflow-hidden {{ targetingSummary.environment }}
                div#view-audience-demographic.mb-2.targeting-summary.demographic(v-if="visibleSections.demographic")
                  .text-overline Demographic
                  .two-lines-truncate.text-body-2.text-overflow-hidden {{ targetingSummary.demographic }}
                div#view-audience-targeted-screens.mb-2.targeting-summary.screen(v-if="visibleSections.screen" v-for='s in targetingSummary.screen')
                  .text-overline.screen-targeting-type {{ s.key }}
                  .one-line-truncate.text-body-2.text-overflow-hidden.screen-targeting-value {{ s.value }}
                moments-read.targeting-summary-moments(
                  v-if='isOnAuctionPackagePage && details && details.momentId'
                  :temperature-scale='temperatureScale'
                  :moment-id='details.momentId'
                  :postEvent='momentsPostEvent'
                  :authToken="$store.getters['user/getToken']")

            //- v-btn.my-6(x-large color='primary' block outlined) Plan your campaign today
            //- .body-2
              p Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pretium elit quis velit malesuada, lobortis pellentesque nisi vehicula. Proin euismod justo ut porta venenatis. Quisque eget odio sem. Aliquam aliquam, dolor at facilisis cursus, sem sem sagittis nunc, et accumsan turpis metus non tellus. Aenean ac hendrerit enim, a bibendum dolor. Aliquam non nisi sodales, dapibus sem et, mattis diam. Ut tincidunt enim nec convallis bibendum.
              p Vestibulum sagittis felis lectus, eu tincidunt leo sollicitudin nec. Sed et orci sed turpis sollicitudin tincidunt. Curabitur porttitor aliquam lacinia. Nunc non eros quis risus sollicitudin gravida. Fusce ultricies, sem nec sodales congue, ipsum mi dapibus elit, at luctus sapien ligula in augue. Donec nec vehicula ante. Cras tincidunt nisl a augue feugiat facilisis. In at venenatis enim, non mattis nunc.

          v-tabs(v-model='tab' :grow='$vuetify.breakpoint.xsOnly')
            v-tab#view-audience-inventory-tab(key='0')
              | Inventory
            v-tab#view-audience-spec-tab(key='1')
              | {{ isOnAuctionPackagePage ? isPublic ? 'Creative Specs' : 'Creatives' : 'Specs' }}
            v-tab#view-audience-private-deals-tab(key='2' v-if='canSeePrivateDealTab')
              | Private Deals
            v-tab#view-audience-demographic-tab(key='3' v-if='canSeeDemographicTab')
              | Demographic
          v-divider

          v-tabs-items(v-model='tab' touchless)
            v-tab-item(key='0')
              forecast-inventory(
                v-if='!isLoading'
                id='view-audience-inventory'
                parent="audience"
                :loading='isForecastVenuesLoading'
                :downloadingInventory='downloadingInventory'
                :items='forecastVenues'
                :page='pagination.page'
                :total-items='venuesNum'
                :has-more-items='pagination.hasMorePages'
                :tab="tab"
                @inventoryListChanged="updateTableToMapSync"
                @downloadInventory='downloadInventory')
            v-tab-item(key='1')
              forecast-specs(
                id='view-audience-spec'
                :required-formats='requiredFormats'
                :assigned-creatives='assignedCreatives'
                :show-assigned='!isPublic && details && details.mergeCreativeFormatRatio'
                :show-cropping-info='details && details.mergeCreativeFormatRatio')
              assignedCreativesTable(
                v-if='isOnAuctionPackagePage && !isPublic && details && details.mergeCreativeFormatRatio'
                parent='viewAuctionPackage')
            v-tab-item(key='2' v-if='canSeePrivateDealTab')
              inventoryDealsTable(
                :parent='"viewAuctionPackage"'
                id='view-audience-private-deals'
                :deals='details && details.deals.map(d => d.deal)'
                :dealsLoading='isLoading')
            v-tab-item(key='3' v-if='canSeeDemographicTab')
              demographicSection(
                id='view-audience-demographic'
                v-if='!isLoading'
                mode='cols'
                :itemId='$route.params.itemId'
                :compareTo='compareTo'
                :processDate='details.updatedOn')

        v-col.ma-0.pa-0(cols=12 md=6 order='first' order-md='last')
          div.sticky-column
            InventoryMap(
              v-if='mapExists && !isLoading'
              v-show='showMapOnly || $vuetify.breakpoint.smAndUp'
              :bound-initial-forecast='componentConfig.campaigns.boundInitialForecastCall'
              :compact-popups='$vuetify.breakpoint.xsOnly'
              :default-latitude='marketVals.mapCenter[1]'
              :default-longitude='marketVals.mapCenter[0]'
              :default-zoom='marketVals.mapZoom'
              :filter-on-move='filterOnMove'
              :forecast-inventory='forecastInventory'
              :forecast-inventory-loading='isForecastInventoryLoading'
              :geo-targets='geoTargets'
              :get-venue-details-func='getVenueDetails'
              :height='mapHeight'
              :map-box-token='mapboxToken'
              readonly
              ref='mapComponent'
              :show-fullscreen-button='$vuetify.breakpoint.smAndUp'
              :show-inventory-summary='false'
              show-venue-popup
              :unit='marketVals.distanceUnit'
              :zoom-on-updated-forecast='pagination.filtering'
              :resolve-geo-json-func='resolveGeoJson'
              :disable-recentering='disableRecentering'
              @filterOnMoveChecked='filterOnMoveChecked'
              @mapBoundsUpdated='mapBoundsUpdated'
            )

    shareDialog(
      parentComponent= 'auctionPackage'
      label= 'Auction Package'
      :shareDialogOpen = 'shareDialogOpen'
      @dialogOpenChanged = 'dialogOpenChanged'
    )

    editAudienceDialog(
      id='view-audience-edit-dialog'
      :audience='details'
      :dialogOpen='editAudienceDialogOpen'
      @close='closeEditAudienceDialog')

    editLineDialog(
      id='view-auction-package-edit-dialog'
      :data='details'
      :dialogOpen='editAuctionPackageDialogOpen'
      @close='closeEditAuctionPackageDialog')

    v-navigation-drawer.troubleshooting-drawer(v-if='details && (!isPublic || can.create.shareLink ) && isOnAuctionPackagePage' v-model='troubleshootingToolOpen' fixed right temporary width='70%')
      troubleshooting-tool(:line='details' :isAuctionPackage='isOnAuctionPackagePage')

</template>

<script>
import audienceApi from '@/services/audience.api'
import audienceService from '@/services/audience.service'
import auctionPackageApi from '@/services/auctionPackage.api'
import auctionPackageService from '@/services/auctionPackage.service'
import geoService from '@/services/geo.service'
import csvService from '@/services/csv.service'
import venuesAPI from '@/services/venues.api'

import helpers from '@/services/helpers.service'
import campsiteConfig from '@/config/campsite.config'
import defaultCurrencyValues from '@/services/defaultCurrencyValues'

import impressionsDistribution from '@/components/impressionsDistribution.vue'
import forecastSpecs from '@/components/forecast.specs.vue'
import forecastInventory from '@/components/forecast.inventory.vue'
import demographicSection from '@/components/demographic.vue'
import editAudienceDialog from '@/components/editAudienceDialog.vue'
import orgPicker from '@/components/organization/organizationPicker'

import momentsRead from '@/web-components/moments/momentsRead.vue'
import editLineDialog from '@/components/editLineDialog.vue'
import troubleshootingTool from '@/components/troubleshootingTool.vue'
import shareDialog from '@/components/shareDialog.vue'
import confirmationDialog from '@/components/confirmationDialog.vue'
import assignedCreativesTable from '@/components/assignedCreativesTable.vue'
import inventoryDealsTable from '@/components/inventoryDealsTable.vue'

import { InventoryMap } from '@ayudasystems/campaign-targeting'

import componentConfigService from '@/services/componentConfig'

import { mapState } from 'vuex'
import moment from 'moment'

import geoBoundingBoxService from '@/services/geo.boundingbox.service'
import tracking from '@/services/tracking'

export default {
  components: {
    impressionsDistribution,
    InventoryMap,
    forecastSpecs,
    assignedCreativesTable,
    forecastInventory,
    editLineDialog,
    demographicSection,
    editAudienceDialog,
    momentsRead,
    troubleshootingTool,
    shareDialog,
    confirmationDialog,
    orgPicker,
    inventoryDealsTable
  },
  created: function () {
    this.getData()
    this.$store.dispatch('general/getExchangesTargeting').then(() => {
      this.loadingExchangeTargeting = false
    })
    this.geoTargetsWithGeoJson = this.selectedGeoTargets
  },
  data () {
    return {
      tab: 0,
      details: null,
      loadingAudience: true,
      loadingExchangeTargeting: true,
      downloadingInventory: false,
      editAudienceDialogOpen: false,
      editAuctionPackageDialogOpen: false,
      moreLocationsDialogOpen: false,
      mapExists: true,
      showMapOnly: false,
      mapInitialized: false,
      isStatusLoading: false,
      momentsPostEvent: null,
      troubleshootingToolOpen: false,
      shareDialogOpen: false,
      actionsMenuOpen: false,
      duplicateConfirmDisabled: false,
      duplicateOrganizationId: null,
      assignedCreatives: [],
      mapboxToken: process.env.VUE_APP_MAPBOX_TOKEN,
      updateGeoTargetsJsonCT: null,
      geoTargetsWithGeoJson: [],
      disableRecentering: false
    }
  },
  mounted () {
    this.$root.$on('refreshWeatherMoment', (lineId) => {
      if (lineId === this.details.lineId) {
        this.momentsPostEvent = { name: 'refresh moment' }
      }
    })
  },
  watch: {
    async selectedGeoTargets () {
      await this.updateGeoTargetsJson()
    }
  },
  computed: {
    ...mapState('audience', {
      initialTargeting: state => state.initialTargeting
    }),
    isLoading () {
      return Boolean(this.loadingAudience || this.loadingExchangeTargeting)
    },
    exchange () {
      if (this.isOnAuctionPackagePage && this.details) {
        return { key: this.details.exchange.key, id: this.details.exchange.id }
      }
      return this.details
        ? this.$store.getters['general/getExchanges'].find(x => x.id === this.details.exchangeId)
        : { key: null }
    },
    can () {
      const permissions = this.$store.getters['user/permissions']('auctionPackage')
      return permissions || {
        create: { shareLink: false },
        update: false
      }
    },
    canSeePrivateDealTab () {
      return this.isOnAuctionPackagePage && !this.isPublic
    },
    canSeeDemographicTab () {
      return Boolean(this.marketVals?.canSeeAudienceDemographics)
    },
    token () {
      return this.$route.query ? this.$route.query.token : null
    },
    isPublic () {
      return !!this.token
    },
    marketVals () {
      return this.$store.getters['general/marketValueFromExchange'](this.exchange.key)
    },
    temperatureScale () {
      return this.marketVals.temperatureScale
    },
    isOnAuctionPackagePage () {
      return this.$route.path.includes('/auction-package') && (this.$store.getters['general/isAuctionPackageVisible'] || this.isPublic)
    },
    hasStartAndEndDate () {
      return Boolean(this.details.startDate && this.details.endDate)
    },
    headTitle () {
      return this.isOnAuctionPackagePage ? this.auctionPackageTitle : this.audienceTitle
    },
    audienceTitle () {
      return this.details ? 'Audience: ' + this.details.name : 'Audience'
    },
    auctionPackageTitle () {
      return this.details ? 'Auction Package: ' + this.details.name : 'Auction Package'
    },
    compareTo () {
      return this.isOnAuctionPackagePage ? 'auctionpackage_' + this.$route.params.itemId : 'audience_' + this.$route.params.itemId
    },
    forecastInventory () {
      return {
        positions: this.$store.getters['audience/forecastInventory'].positions
          .filter(p => p.target.size > 0)
          .map(x => {
            const copy = JSON.parse(JSON.stringify(x))
            if (x.target.size < x.size) copy.size = x.target.size
            return copy
          })
      }
    },
    statusColor () {
      return this.details ? this.details.status === 'Active' ? 'success' : 'grey' : 'grey'
    },
    isForecastInventoryLoading () {
      return this.$store.getters['audience/forecastInventoryLoading']
    },
    forecastVenues () {
      var forecastVenues = this.$store.getters['audience/forecastVenues']
      if (forecastVenues) return forecastVenues.venues
      else return []
    },
    isForecastVenuesLoading () {
      return this.$store.getters['audience/forecastVenuesLoading']
    },
    selectedGeoTargets () {
      return this.$store.getters['audience/selectedGeoTargets']
    },
    pagination () {
      return this.$store.getters['audience/getPagination']
    },
    filterAsImove () {
      return this.$store.getters['audience/filterAsImove']
    },
    forecast () {
      return this.$store.getters['audience/forecast']
    },
    forecastLoading () {
      return this.$store.getters['audience/forecastLoading']
    },
    requiredFormats () {
      return this.forecast.formats
    },
    impressionsNum () {
      return this.details?.momentId ? 'N/A' : helpers.shortenNumber(this.forecast.impressions.value)
    },
    venuesNum () {
      return this.formatNumber(this.forecast.inventory.numberOfVenues)
    },
    screensNum () {
      return this.formatNumber(this.forecast.inventory.numberOfScreens)
    },
    bidRange () {
      const bidRange = this.forecast.dealBidRange
      if (!bidRange) return 'N/A'

      const min = this.getMinCeilingPrice(bidRange)
      const max = this.getMaxCeilingPrice(bidRange)
      const currencySymbol = this.isOnAuctionPackagePage
        ? defaultCurrencyValues(this.details.currency.code).currencySymbolString
        : this.marketVals.currencySymbolString

      return min === max
        ? helpers.formatMoney(min, 2, currencySymbol)
        : `${helpers.formatMoney(min, 2, currencySymbol)} to ${helpers.formatMoney(max, 2, currencySymbol)}`
    },
    exchangeTaxonomy () {
      return this.exchange && this.exchange.key
        ? this.$store.getters['general/allTaxonomies'][this.exchange.key]
        : []
    },
    exchangeTargetGroups () {
      return this.exchange && this.exchange.key
        ? this.$store.getters['general/allTargetGroups'][this.exchange.key]
        : []
    },
    exchangeTargets () {
      return this.exchange && this.exchange.key
        ? this.$store.getters['general/allTargets'][this.exchange.key]
        : []
    },

    targetingSummary () {
      return {
        location: this.details
          ? audienceService.formatGeoTargetsFull(this.details.geography, 2, this.marketVals.distanceUnit)
          : null,
        environment: this.details
          ? audienceService.formatEnvironments(this.details.segments, this.exchangeTaxonomy)
          : null,
        demographic: this.details
          ? audienceService.formatMobileTargets(this.details.audienceSegments, this.exchangeTargetGroups, this.marketVals.currencySymbolString)
          : null,
        screen: this.details
          ? audienceService.formatScreenIntoKeyValueList(this.details.audienceSegments, this.initialTargeting, this.exchangeTargets)
          : null
      }
    },
    visibleSections () {
      return {
        location: !!this.targetingSummary.location,
        environment: !!this.targetingSummary.environment,
        demographic: this.marketVals.canSeeMobileTargeting && !!this.targetingSummary.demographic,
        screen: this.marketVals.canSeeScreens && !!this.targetingSummary.screen
      }
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    mapHeight () {
      const offsetHeight = campsiteConfig.barsHeight.appNavbar
      return 'calc(100vh - ' + offsetHeight + 'px)'
    },
    geoTargets () {
      return this.geoTargetsWithGeoJson
        .filter(geoTarget => geoTarget.type !== 'point_of_interest')
        .map(this.convertGeoTargetToMapFormat)
    },
    filterOnMove () {
      return {
        isVisible: this.tab === 0 && this.$vuetify.breakpoint.smAndUp,
        value: this.filterAsImove
      }
    },
    mapViewPort () {
      return this.$store.getters['audience/getMapViewPort']
    }
  },
  methods: {
    formatsApDates (date) {
      return moment(date).format('MMMM Do, YYYY h:mm A')
    },
    updateStatus () {
      this.isStatusLoading = true
      var status = this.details.status === 'Active' ? 'Inactive' : 'Active'
      auctionPackageApi.editAuctionPackage(this.details.id, { status, exchange: { id: this.exchange.id } }).then(resp => {
        if (resp.status === 200) {
          this.details.status = status
          this.$store.commit('snackbar/setSnackbar', {
            type: 'success',
            msg: `Auction Package ${this.details.name} successfully updated`
          })
        }
      })
        .catch(error => {
          let msg = error.response.data.errors[0]
          if (msg.errorCode === 'unexpected') {
            msg = 'Unexpected: ' + msg.id
          } else msg = msg.message

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
        .finally(() => {
          this.isStatusLoading = false
        })
    },
    getData () {
      this.loadingAudience = true
      if (this.isOnAuctionPackagePage) {
        this.$store.commit('auctionPackage/resetAuctionPackage')
        this.$store.commit('audience/resetAudience')
        auctionPackageApi.getAuctionPackage(this.$route.params.itemId)
          .then(async res => {
            this.details = Object.assign({}, res.data)
            if (this.isPublic && this.details.organization.id === campsiteConfig.organizationIdBroadsignPublicCatalog) {
              tracking.bootAnonymous(this.details.organization.id)
            }
            await this.$store.dispatch('audience/loadAudience', this.details)
            this.$store.commit('audience/setCurrency', { code: this.details.currency.code })
            this.$store.commit('auctionPackage/setAuctionPackageId', this.details.id)
            this.$store.commit('auctionPackage/storeExchangeDetails', this.details)
            this.$store.dispatch('auctionPackage/getAssignmentsCount', this.details.id)
            this.$store.commit('auctionPackage/setMomentId', this.details.momentId)
            this.$store.commit('auctionPackage/setAdvertiser', {
              buyerId: this.details.organization.id,
              name: this.details.organization.name
            })
            this.updateForecast()
            this.updateForecastInventory()
            this.updateForecastVenues()

            auctionPackageApi.getAssignments(0, null, 'creativeid', 'desc', this.details.id)
              .then(assignments => {
                this.assignedCreatives = assignments.map(a => ({ ...a.creative, id: a.creativeId }))
              })
            this.loadingAudience = false
          })
          .catch(err => {
            var name = '404'

            if (err) {
              if ((err.response && err.response.status === 401) || err === 'No current user') {
                name = 'Access Denied'
              }
            }

            this.$router.push({ name })
          })
      } else {
        audienceApi.getAudience(this.$route.params.itemId)
          .then(async res => {
            this.details = Object.assign({}, res.data)
            await this.$store.dispatch('audience/loadAudience', this.details)
            this.updateForecast()
            this.loadingAudience = false
          })
          .catch(() => {
            this.$router.push({ name: '404' })
          })
      }
    },
    formatNumber (nbr) {
      return helpers.shortenNumber(nbr, 1)
    },
    formatScreens (audienceSegments) {
      return audienceService.formatScreens(audienceSegments)
    },
    async mapBoundsUpdated (mapApiPoco) {
      if (!this.mapInitialized) { this.mapInitialized = true }

      this.$store.commit('audience/setMapBounds', mapApiPoco)
      this.$store.commit('audience/setMapViewPort', mapApiPoco)
      await this.updateGeoTargetsJson(true)

      const pagination = {}
      if (this.filterAsImove) { pagination.page = 1 }
      if (this.pagination.filtering) { pagination.filtering = false }
      if (Object.keys(pagination).length) {
        this.$store.commit('audience/setPagination', pagination)
      }

      if (!this.editAuctionPackageDialogOpen) {
        this.updateForecastInventory()
      }

      if (this.filterAsImove) {
        this.updateForecastVenues()
      }
    },
    updateTableToMapSync (pagination) {
      // check if "filtering" BEFORE changing value in Store
      pagination.filtering = this.pagination.query !== pagination.query

      this.$store.commit('audience/setPagination', pagination)
      this.updateForecastVenues()

      if (this.pagination.filtering && !this.$vuetify.breakpoint.xsOnly) {
        this.updateForecastInventory()
      }
    },
    getCommonForecastOptions () {
      var apEndDate = new Date()
      apEndDate.setDate(apEndDate.getDate() + 7)

      const options = {
        mapId: this.isOnAuctionPackagePage ? this.details.lineId : this.details.id,
        buyerId: this.details.organizationId,
        exchange: this.exchange.key,
        targeting: this.details.audienceSegments || this.details.targeting,
        startDate: this.isOnAuctionPackagePage ? new Date().toISOString() : this.details.startDate,
        endDate: this.isOnAuctionPackagePage ? apEndDate.toISOString() : this.details.endDate,
        auctionPackageId: this.isOnAuctionPackagePage ? this.details.id : null,
        deals: this.isOnAuctionPackagePage ? this.details.deals.map(d => d.code) : null,
        usePublicExchange: this.isOnAuctionPackagePage ? this.details.usePublicExchange : null,
        currency: this.isOnAuctionPackagePage ? this.details.currency.code : null,
        percentiles: this.isOnAuctionPackagePage ? [1, 100] : null,
        returnNativeResolution: this.isOnAuctionPackagePage ? !this.details.mergeCreativeFormatRatio : null
      }
      if (this.details.geography && Object.keys(this.details.geography).length > 0) {
        // do NOT send geoJSON(s) to Forecast for performance
        // easier to go through "selectedGeoTargets" and re-format
        // than trying to go through already formated "geography"
        var selectedGeoTargetsNoGeoJSON = geoService.removeGeoJSON(this.selectedGeoTargets)
        options.geography = geoService.geoTargetsToApiFormatting(selectedGeoTargetsNoGeoJSON)
      }

      if (this.details.segments && this.details.segments.groups && this.details.segments.groups.length > 0) {
        options.segments = this.details.segments
      }

      return options
    },
    updateForecastInventory () {
      if (!this.mapInitialized || !this.details) {
        return
      }
      this.$store.dispatch('audience/updateForecastInventory', this.getCommonForecastOptions())
    },
    updateForecast () {
      this.$store.dispatch('audience/updateForecast', this.getCommonForecastOptions())
    },
    updateForecastVenues () {
      if (!this.details) {
        return
      }
      this.$store.dispatch('audience/updateForecastVenues', this.getCommonForecastOptions())
    },
    downloadInventory (scope) {
      this.downloadingInventory = true

      const options = this.getCommonForecastOptions()
      audienceApi.getListTargetedInventory(options, scope)
        .then(res => {
          const name = this.details.name || 'inventory'
          csvService.validateAndExportCsv(res, name)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.downloadingInventory = false
        })
    },
    exportCsv () {
      auctionPackageApi.getAuctionPackageListForCSV({ ids: [this.details.id] }).then(res => {
        csvService.csvExport(res.map(x => auctionPackageService.csvFormatter(x)), 'AuctionPackages_' + moment().format('DD-MM-YYYY'))
      })
    },
    toggleView () {
      this.mapExists = false
      setTimeout(() => {
        this.mapExists = true
        this.showMapOnly = !this.showMapOnly
      }, 70)
    },
    generateShareToken () {
      this.shareDialogOpen = true
      this.$store.dispatch('auctionPackage/generateOrGetToken')
    },
    dialogOpenChanged (dialogState) {
      this.shareDialogOpen = dialogState
    },
    openEditAudienceDialog () {
      this.editAudienceDialogOpen = true
    },
    closeEditAudienceDialog (audienceUpdated = false) {
      this.editAudienceDialogOpen = false
      if (audienceUpdated) {
        this.getData()
      }
    },
    openEditAuctionPackageDialog () {
      this.editAuctionPackageDialogOpen = true
    },
    closeEditAuctionPackageDialog (updated = false) {
      this.editAuctionPackageDialogOpen = false
      if (updated) {
        this.details = null
        this.getData()
        this.momentsPostEvent = { name: 'refresh moment' }
      } else {
        this.$store.commit('auctionPackage/setAuctionPackageId', this.$route.params.itemId)
        this.updateForecast()
      }
    },
    onOrgPickerChange ({ isValid, organizationId }) {
      this.duplicateConfirmDisabled = !isValid
      this.duplicateOrganizationId = organizationId
    },
    duplicateAuctionPackage () {
      this.actionsMenuOpen = false
      this.$store.dispatch('auctionPackage/duplicate', { auctionPackageId: this.details.id, organizationId: this.duplicateOrganizationId })
        .then(copy => {
          if (copy) {
            const msg = this.details.name + ' successfully duplicated'
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: msg
            })
            window.open(`${window.location.origin}/auction-packages/${copy.id}`, '_blank')
          } else {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: 'Something went wrong while duplicating auction package.'
            })
          }
        })
        .catch(error => {
          let msg = error.response.data.errors[0].message
          if (msg.errorCode === 'unexpected') {
            msg = 'Unexpected: ' + msg.id
          } else msg = msg.message

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },
    archiveAuctionPackage () {
      this.actionsMenuOpen = false
      auctionPackageApi.editAuctionPackage(this.details.id,
        {
          status: this.details.status !== 'Archived' ? 'Archived' : 'Inactive',
          exchange: { id: this.details.exchange.id, key: this.details.exchange.key }
        }).then(removed => {
        if (removed) {
          const msg = this.details.name + ' successfully archived'
          this.$store.commit('auctionPackage/resetAuctionPackage')
          this.$store.commit('audience/resetAudience')
          this.$store.commit('snackbar/setSnackbar', {
            type: 'success',
            msg: msg
          })
          this.$router.push({ name: 'Auction Packages' })
        } else {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: 'Something went wrong while archiving auction package.'
          })
        }
      })
        .catch(error => {
          let msg = error.response.data.errors[0]
          if (msg.errorCode === 'unexpected') {
            msg = 'Unexpected: ' + msg.id
          } else msg = msg.message

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
        .finally(() => {
          this.isStatusLoading = null
        })
    },
    getVenueDetails (venueId) {
      return venuesAPI.getVenueDetails(venueId)
    },
    filterOnMoveChecked (isChecked) {
      this.$store.commit('audience/setFilterAsImove', isChecked)
    },
    getMinCeilingPrice (bidRange) {
      return Math.ceil(bidRange.minCpmps * campsiteConfig.creatives.defaultDuration.image * 100) / 100
    },
    getMaxCeilingPrice (bidRange) {
      return Math.ceil(bidRange.maxCpmps * campsiteConfig.creatives.defaultDuration.image * 100) / 100
    },
    async resolveGeoJson (geographyId, geoJSONFileUrl) {
      return await geoService.resolveGeoJson(geographyId, geoJSONFileUrl)
    },
    convertGeoTargetToMapFormat (geoTarget) {
      return {
        isIncluded: geoTarget.isIncluded,
        radius: geoTarget.radius,
        isUnrendered: geoTarget.isOutsideOfViewPort,
        geolocation: {
          label: geoTarget.label,
          value: geoTarget.value,
          type: geoTarget.type,
          geography: {
            latitude: geoTarget.geography?.latitude,
            longitude: geoTarget.geography?.longitude,
            geoJSON: geoTarget.geography?.geoJSON
          }
        }
      }
    },
    async updateGeoTargetsJson (disableRecentering = false) {
      if (this.updateGeoTargetsJsonCT) {
        this.updateGeoTargetsJsonCT.cancel('Request canceled by a newer request.')
        this.updateGeoTargetsJsonCT = null
      }

      const { getUpdatedGeoTargets, cancelTokenSource } = geoBoundingBoxService.getGeoTargetsJsonUpdater(this.selectedGeoTargets, this.mapViewPort, disableRecentering)
      if (!getUpdatedGeoTargets) return

      this.updateGeoTargetsJsonCT = cancelTokenSource
      try {
        this.geoTargetsWithGeoJson = await getUpdatedGeoTargets()
        this.disableRecentering = disableRecentering
      } catch (e) {} finally {
        this.updateGeoTargetsJsonCT = null
      }
    }
  }
}
</script>
