<template lang="pug">
  v-dialog(v-model='isDialogOpen' @click:outside="closeDialog" max-width='850' style='height: inherit;')
    template(v-slot:activator='{on}')
      slot(name='customActivator')
    v-card
      v-card-title.text-h5.dialog-title Generate Auction Packages
      v-card(flat height=500 style='overflowY: scroll;')
        v-card-text.py-0
          | Set an External ID to tie this plan and the generated auction packages together
          v-text-field.mt-2.proposal-external-ID(v-model='externalId' :label="`External ID`" hide-details :disabled='auctionPackagesAreGenerated')
          v-switch.activate-upon-creation(v-model='activateUponCreation' :disabled='auctionPackagesAreGenerated'  :label="`Activate Auction Package upon creation`" hide-details)
          v-switch.advertiser-name-as-prefix(v-model='advertiserAsNamePrefix' :disabled='auctionPackagesAreGenerated' :label="`Add advertiser as auction package name prefix`" hide-details)
        v-card-text.mt-6.pb-0 Select where to generate auction packages
          orgPicker(
            :key='isDialogOpen'
            :disabled='someLinesHaveDeals || auctionPackagesAreGenerated'
            ref='orgPicker'
            :onChange='onOrgPickerChange'
            sameOrgLabel='In the same organization'
            diffOrgLabel='In another organization'
            :onlyDspPartners='true'
          )
          div.mt-4.mb-2
            | Select lines to generate auction packages. Once ready, they will be available in the auction packages section.
        v-data-table.table-proposal-lines.mt-0.cs-data-table(
          ref='proposalLinesTable'
          density='compact'
          :headers='headers'
          :items='linesForAuctionPackages'
          v-model='selected'
          :hide-default-header='$vuetify.breakpoint.xsOnly'
          hide-default-footer
          disable-pagination
          no-data-text='No lines found'
        )
          template.text-left(v-slot:item.checkbox="{ item }")
              v-checkbox.mt-0.pt-0.proposal-line-selected(:input-value="selected.includes(item)" @change="toggleSelection(item)" :disabled="auctionPackagesAreGenerated" hide-details color='primary')

          template(v-slot:item.name="{ item }")
            .d-flex.align-center
              span.proposal-line-name {{ item.name }}

          template(v-slot:item.creationApiStatus="{ item }")
            v-progress-circular.row-loading(indeterminate color="grey lighten-2" size=24 v-if="item.creationApiStatus === 'loading'")
            v-icon.row-valid(color='success' v-if="item.creationApiStatus === 'success'") mdi-check-circle
            v-icon.row-error(color='red lighten-1' v-if="item.creationApiStatus === 'error'") mdi-minus-circle
      v-divider
      v-card-actions
        v-spacer
        v-btn.close-dialog(color='secondary' text @click='closeDialog') Close
        v-btn.generate-auction-packages(color='primary' text @click='generateAuctionPackages' :disabled="!isValidForm || saved" v-if="!saved") Generate {{ selected.length }} Packages
        v-btn.goto-auction-packages-page(color='primary' text @click='goToAuctionPackagePage' :disabled="!auctionPackagesAreGenerated" v-if="saved") Go to Auction Packages
</template>

<script>
import orgPicker from '@/components/organization/organizationPicker'

import auctionPackageApi from '@/services/auctionPackage.api'
import auctionPackageService from '@/services/auctionPackage.service'

export default {
  components: {
    orgPicker
  },
  props: ['lines', 'authToken', 'proposal'],
  data () {
    return {
      isDialogOpen: false,
      saved: null,
      selected: [],
      externalId: '',
      activateUponCreation: null,
      advertiserAsNamePrefix: null,
      linesForAuctionPackages: [],
      isValidForm: null,
      targetOrganizationId: null
    }
  },
  mounted () {
    this.$root.$on('openGenerateAuctionPackagesDialog', () => {
      this.setStateValues(true)
      this.externalId = `${this.proposal.name} (${this.proposal.id})`
    })
  },
  watch: {
    isDialogOpen (newV) {
      if (newV) {
        this.linesForAuctionPackages = JSON.parse(JSON.stringify(this.lines))
        this.selected = [...this.linesForAuctionPackages]
      }
    },
    advertiserAsNamePrefix (newV) {
      const prefix = `${this.proposal.account} - `
      this.selected.forEach(line => {
        if (newV === true) {
          line.name = prefix + line.name
        } else {
          line.name = line.name.replace(prefix, '')
        }
      })
    }
  },
  computed: {
    headers () {
      const headers = [
        { align: 'left', value: 'checkbox', width: '30px', sortable: false },
        { text: 'Line Name', align: 'left', value: 'name', sortable: false },
        { align: 'end', text: 'Status', value: 'creationApiStatus', sortable: false }
      ]

      return headers
    },
    someLinesHaveDeals () {
      return this.lines.some(line => line.deals?.length > 0)
    },
    auctionPackagesAreGenerated () {
      return !!(this.saved && this.saved.externalId)
    }
  },
  methods: {
    closeDialog () {
      this.setStateValues(false)
      this.saved = null
    },
    setStateValues (value) {
      this.isDialogOpen = value
      this.activateUponCreation = value
      this.advertiserAsNamePrefix = value
      this.isValidForm = value
    },
    async generateAuctionPackages () {
      this.saved = { }
      this.setAuctionPackagesFields()
      await this.batchGenerateAuctionPackages([...this.selected])
      this.saved = { externalId: this.externalId }
    },
    async batchGenerateAuctionPackages (lines) {
      if (this.destroyed || !lines.length) return

      const batch = lines.splice(0, 10).map(async (line) => {
        try {
          line.creationApiStatus = 'loading'
          this.$forceUpdate()
          if (!line.geoLoaded) {
            line.geography = (await this.$store.dispatch('proposals/getLine', line.id)).geography
          }
          const auctionPackage = await auctionPackageService.createAuctionPackageFromLine(
            line,
            this.authToken,
            this.activateUponCreation ? 'Active' : 'Inactive',
            this.proposal.buyerId
          )
          await auctionPackageApi.createAuctionPackage(auctionPackage,
            this.targetOrganizationId ?? this.proposal.buyerId)
          line.creationApiStatus = 'success'
        } catch (error) {
          line.creationApiStatus = 'error'
          this.$logger.error('proposal.generateAuctionPackage', { createAuctionPackageErrorMsg: error.toString() })
        }
        this.$forceUpdate()
      })
      await Promise.all(batch)
      await this.batchGenerateAuctionPackages(lines)
    },
    toggleSelection (item) {
      const index = this.selected.findIndex(i => i.id === item.id)
      if (index >= 0) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(item)
      }
    },
    setAuctionPackagesFields () {
      this.selected.forEach(line => {
        line.externalId = this.externalId
      })
      return this.selected
    },
    onOrgPickerChange ({ isValid, organizationId }) {
      this.isValidForm = isValid
      this.targetOrganizationId = organizationId
    },
    goToAuctionPackagePage () {
      this.$router.push({ path: '/auction-packages/', query: { search: this.saved.externalId } })
    }
  }
}
</script>
