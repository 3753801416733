<template lang='pug'>
  v-expansion-panel#line-priority-panel
    v-expansion-panel-header.text-h6 Line Priority
    v-expansion-panel-content(eager)
      v-form(ref='linePriority')
        v-row.my-0(row, wrap, justify='end')
          v-col.pt-0
            v-select#linepriority-field.mt-0(
              ref='linePrioritySelect'
              label='Line Order Priority'
              item-text='name'
              item-value='id'
              return-object
              :items='linePriorities'
              :value='linePriority',
              :rules='[rules.required]'
              @input='linePriorityChanged'
              flat
            )
</template>

<script>
import userApi from '../../services/user.api'

export default {
  props: ['parentLoading'],
  data () {
    return {
      rules: {
        required: (value) => !!value || 'Required.'
      },
      linePriority: null,
      linePriorities: []
    }
  },
  created () {
    userApi.getOrganizationPriorities(this.orgId)
      .then(linePriorities => {
        this.linePriorities = linePriorities

        if (!this.parentLoading) {
          this.initialize()
        } else {
          const unwatch = this.$watch('parentLoading', isLoading => {
            if (!isLoading) {
              this.initialize()
              unwatch()
            }
          })
        }
      })
  },
  mounted () {
    this.$root.$on('validateLinePriority', () => {
      this.$emit('formValidationUpdated', this.$refs.linePriority?.validate(), 'linePriority')
    })
  },
  computed: {
    orgId () {
      return this.$store.getters['user/getOrganization'].id
    }
  },
  methods: {
    initialize () {
      this.linePriority = this.$store.getters['createCampaign/getLinePriority']
      if (!this.linePriority) {
        this.setDefaultPriority()
      }
    },
    setDefaultPriority () {
      const defaultPriorityName = 'Standard'
      this.linePriorityChanged(this.linePriorities.find(priority => priority.name === defaultPriorityName))
    },
    linePriorityChanged (linePriority) {
      this.$store.commit('createCampaign/setLinePriority', linePriority)
      this.linePriority = linePriority
    }
  }
}
</script>
