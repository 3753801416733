<template lang="pug">
  div
    div.pl-0.pt-1.pb-3
      div.text-body-2 Narrow down the inventory selection by using these screen level targets

    targetWithChipsAutocomplete(
      v-if='!isSaasOrg'
      id='screenTargetPublishers'
      targetRef='Publishers'
      @targetChanged="$emit('storeUpdated')"
      )

    targetWithTreeview(
      id='screenTargetCreativeFormats'
      targetRef='CreativeFormats'
      @targetChanged="$emit('storeUpdated')"
      )

    targetWithTreeview(
      id='screenTargetCreativeSizes'
      targetRef='CreativeSizes'
      @targetChanged="$emit('storeUpdated')"
    )

    targetWithChipsAutocomplete(
      v-if='componentConfig.audiences.isMeasurementPartnersShown'
      id='screenTargetMeasurementPartners'
      targetRef='MeasurementPartners'
      @targetChanged="$emit('storeUpdated')"
      )

    div#screenTargetSizeAndOrientation(v-if="isCanadianExch && hasDeprecatedTargeting")
      div(v-if='!isSectionExpanded')
        v-btn.ma-0(text color='primary' @click='expand()')
          v-icon(left) mdi-plus
          | Screen Size Range
      v-card.d-block.mb-3(color='grey lighten-4' v-else)
        v-card-title.pt-2 Screen Size Range
          v-spacer
          v-btn(icon small @click='close()')
            v-icon(color='grey') mdi-close
        v-card-subtitle
          .text-caption This target is deprecated
          //- .text-caption Please use the new and more granular "Diagonal Length" target.
        v-card-text.pt-0
          v-range-slider.px-3.pt-0.pb-3(
            v-if='availableInfo.showSizes'
            :tick-labels='tics.labels'
            v-model='selectedTicValues'
            min='0'
            :max='tics.labels.length - 1'
            color='primary'
            hide-details
            class='SliderScreen'
            @change='updateScreenSize'
          )
</template>

<script>
import targetWithChipsAutocomplete from '@/components/audience/audience.targetWithChipsAutocomplete'
import targetWithTreeview from '@/components/audience/audience.targetWithTreeview'

import componentConfigService from '@/services/componentConfig'
import billingService from '@/services/billing.service'

export default {
  components: {
    targetWithChipsAutocomplete,
    targetWithTreeview
  },
  data () {
    return {
      isSectionExpanded: false,
      selectedTicValues: [],
      hasDeprecatedTargeting: false,
      paymentMethod: {}
    }
  },
  computed: {
    tics () {
      return this.$store.getters['audience/screenTargets']
    },
    screenSizeFromStore () {
      return this.$store.getters['audience/selectedScreenSize']
    },
    availableInfo () {
      return this.$store.getters['audience/availableScreenInfo']
    },
    organization () {
      return this.$store.getters['user/getOrganization']
    },
    isCanadianExch () {
      return this.organization ? this.organization.currency.code === 'CAD' : false
    },
    isSaasOrg () {
      return this.paymentMethod?.key === 'saas-for-publishers' || false
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  created: function () {
    if (this.screenSizeFromStore.length) {
      this.hasDeprecatedTargeting = true
      this.prefillSection()
      this.expand()
    } else this.selectedTicValues = [0, this.tics.labels.length - 1]

    this.getOrganizationPaymentMethods()
  },
  watch: {
    screenSizeFromStore: function () {
      if (this.screenSizeFromStore.length) {
        this.prefillSection()
        this.expand()
      } else this.selectedTicValues = [0, this.tics.labels.length - 1]
    }
  },
  methods: {
    close () {
      this.isSectionExpanded = false
      this.$store.dispatch('audience/updateScreenTargetsAction', { field: 'screenSize', values: [] })
      this.$emit('storeUpdated')
    },
    expand () {
      this.isSectionExpanded = true
    },
    updateScreenSize () {
      let vals = [...this.tics.values.slice(this.selectedTicValues[0], this.selectedTicValues[1] + 1)]
      if (vals.length === this.tics.values.length) { vals = [] }
      this.$store.dispatch('audience/updateScreenTargetsAction', { field: 'screenSize', values: vals })
      this.$emit('storeUpdated')
    },
    prefillSection () {
      if (this.screenSizeFromStore.length && this.tics.values) {
        const size = this.tics.values.filter(x => this.screenSizeFromStore.includes(x))
        this.selectedTicValues = [size[0], size[size.length - 1]].map(x => this.tics.values.indexOf(x))
      }
    },
    getOrganizationPaymentMethods () {
      billingService.getActivePaymentMethods(this.organization.id)
        .then(paymentMethods => {
          if (paymentMethods.length) {
            this.paymentMethod = paymentMethods[0].paymentMethod
          }
        })
    }
  }
}
</script>

<style>
  .SliderScreen .v-slider__ticks>span{
    font-size:14px;
  }
</style>
