<template lang="pug">
  div
    v-radio-group.mt-0.org-picker-option-group(:disabled='disabled' v-model='useDifferentOrg' hide-details)
      v-radio.same-org-option(:label='sameOrgLabel' value='false' color='primary')
      v-radio.different-org-option(:label='diffOrgLabel' value='true' color='primary')
    v-autocomplete.organization-select(
      v-if='useDifferentOrg === "true"'
      label='Select Organization'
      v-model='organizationId'
      :items='organizations'
      item-text='name'
      item-value='id'
      color='primary'
      :loading='fetchingOrganizations')
</template>

<script>
import helpersService from '@/services/helpers.service'
import userApi from '@/services/user.api'

export default {
  props: {
    onChange: {
      type: Function,
      default: () => {}
    },
    sameOrgLabel: {
      type: String,
      default: ''
    },
    diffOrgLabel: {
      type: String,
      default: ''
    },
    filters: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onlyDspPartners: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      useDifferentOrg: 'false',
      organizations: [],
      fetchingOrganizations: true,
      organizationId: null
    }
  },
  computed: {
    returnedValue () {
      return {
        isValid: this.useDifferentOrg === 'true' ? Boolean(this.organizationId) : true,
        organizationId: this.useDifferentOrg === 'true' ? this.organizationId : null
      }
    }
  },
  watch: {
    returnedValue (value) {
      if (this.onChange) {
        this.onChange(value)
      }
    }
  },
  created () {
    if (!this.organizations.length) {
      this.fetchingOrganizations = true

      const getMethod = this.onlyDspPartners ? userApi.getDspPartnerOrganizations : userApi.getOrganizationsAdv

      getMethod({ selectAll: true, filters: this.filters, select: 'name, id' })
        .then(organizations => {
          this.organizations = organizations.sort(helpersService.sortByName)
          this.fetchingOrganizations = false
        })
    }
  }
}
</script>
