<template lang="pug">
div
  v-toolbar.elevation-0(color="transparent")
    .headline.hidden-sm-and-down
      span#deals-page-title.text-capitalize {{ dealLabel }}
    v-text-field#deals-find-field(v-model="search" v-if="hasBuyerSeat || isDataLoading" label='Find Deals' prepend-inner-icon='mdi-magnify' solo hide-details clearable :class="{'mx-4': $vuetify.breakpoint.smAndUp }")

    v-tabs#deals-tabs(background-color='transparent' slider-color='primary' slot='extension' v-model='currentTab')
      v-tab#all(ripple color='primary lighten-4')
        | All
      v-tab#archived(ripple)
        | Archived
  v-scroll-y-reverse-transition
    v-toolbar#deals-toolbar(color='secondary lighten-1' v-if='isOverview' dark dense flat width='100%' :class="{'toolbar-touch': $vuetify.breakpoint.smAndDown, 'toolbar-desktop': $vuetify.breakpoint.mdAndUp }" v-show='selected.length')
      span.hidden-sm-and-down
        v-toolbar-title#deals-selected.subtitle-1(v-if="!selectAll")
          | {{ `${selected.length} ${selected.length > 1 ? 'deals' : 'deal'} selected` }}
        v-toolbar-title#all-deals-selected.subtitle-1(v-if="selectAll")
          | {{ `All ${totalItems} deals are selected` }}

      span.hidden-md-and-up
        v-chip.ma-1(v-if='!selectAll' outlined color='white' small)
          | {{ selected.length }}
        v-chip.ma-1(v-if='selectAll' dark color='white' class="secondary--text" small)
          | {{ totalItems }}

      v-btn.ml-2#select-all-btn(
        v-if='!selectAll && selected.length === deals.length && deals.length < totalItems'
        text outlined small dark
        @click='selectAll = true'
        ) Select all
        span.hidden-sm-and-down
          b &nbsp;{{ totalItems }}&nbsp;
          | deals

      v-toolbar-items.ml-3
        v-btn#create-campaign-btn(
          v-if="this.selected.length < 50 && !selectAll"
          :icon="$vuetify.breakpoint.smAndDown"
          :text="$vuetify.breakpoint.mdAndUp"
          @click="createCampaign()"
          )
          v-icon(:small="$vuetify.breakpoint.mdAndUp") mdi-folder-plus-outline
          span.ml-2.hidden-sm-and-down Create Campaign

        v-btn#download-csv-btn(
          :icon="$vuetify.breakpoint.smAndDown"
          :text="$vuetify.breakpoint.mdAndUp"
          :loading="isDownloadingCsv"
          @click="downloadCsv"
          )
          v-icon(:small="$vuetify.breakpoint.mdAndUp") mdi-download
          span.ml-2.hidden-sm-and-down Export CSV

        confirmationDialog#archive-deal-dialog(
          v-if='currentTab === 0 && !selectAll'
          @confirmed="archiveDeals"
          :details="{title: 'Archive Deals', msg: `Are you sure you want to archive ${this.selected.length > 1 ? 'these deals' : 'this deal' }?`, btnLabel: 'Archive Deals'}"
          )
          template(v-slot:customActivator='')
            v-btn#archive-btn(
              :icon="$vuetify.breakpoint.smAndDown"
              :text="$vuetify.breakpoint.mdAndUp"
              :loading="isArchiving"
              @click="$root.$emit('openConfirmDialog', 'Archive Deals')"
              )
              v-icon(:small="$vuetify.breakpoint.mdAndUp") mdi-delete
              span.ml-2.hidden-sm-and-down Archive

        confirmationDialog#unarchive-deal-dialog(
          v-if='currentTab === 1 && !selectAll'
          @confirmed="unarchiveDeals"
          :details="{title: 'Restore Deals', msg: `Are you sure you want to restore ${this.selected.length > 1 ? 'these deals' : 'this deal' }?`, btnLabel: 'Restore Deals'}"
          )
          template(v-slot:customActivator='')
            v-btn#restore-btn(
              :icon="$vuetify.breakpoint.smAndDown"
              :text="$vuetify.breakpoint.mdAndUp"
              :loading="isArchiving"
              @click="$root.$emit('openConfirmDialog', 'Restore Deals')"
              )
              v-icon(:small="$vuetify.breakpoint.mdAndUp") mdi-package-up
              span.ml-2.hidden-sm-and-down Restore

      v-spacer
      v-btn.ml-3(icon @click="selected = []")
          v-icon mdi-close
  v-divider
  v-data-table.cs-data-table(
    v-model="selected"
    :headers="headers"
    :items="deals"
    :loading="isDataLoading"
    :options.sync='pagination'
    item-key="code"
    :server-items-length='totalItems'
    :footer-props="{itemsPerPageOptions: pagination.itemsPerPageOptions}"
    show-select
    must-sort
  )

    template(v-slot:no-data='')
      div#deal-no-seat-message.my-12(v-if='!hasBuyerSeat')
        .text-h5 No seat set
        .text-body-2 Contact your sales representative to enable your seat.
      div#deal-no-deal-message.my-12(v-if='hasBuyerSeat')
        .text-h5 {{ noDataText }}

    template(v-slot:item.code="{ item }")
      .deal-code
        .primary--text.text-left(width='300px' @click="isOverview && createCampaign(item)")
        v-chip.deal-code-chip(label :style='isOverview ? "cursor: pointer;" : "cursor:default;"' small @click="isOverview && createCampaign(item)")
          v-icon.mr-1(small v-if="item.status === 'Active'" color='primary') mdi-tag
          v-icon.mr-1(small v-if="item.status=== 'Inactive'" color='grey') mdi-tag
          strong {{ item.code }}

    template(v-slot:item.publisher="{ item }")
      .deal-publisher
        | {{ item.source.key }}

    template(v-slot:item.name="{ item }")
      .deal-name
        | {{ item.name }}

    template(v-slot:item.floorCPM="{ item }")
      .deal-price(style='white-space: pre;')
        | {{ getFloorCPMsLabel(item.floorCPMs) }}
        br
        | {{ item.auctionType }}

    template(v-slot:item.venueCount="{ item }")
      .d-flex.align-center.justify-end.deal-venue-count
        v-icon.text--secondary(small) mdi-map-marker
        | {{ item.venueCount | numberWithCommas }}

    template(v-slot:item.screenCount="{ item }")
      .d-flex.align-center.justify-end.deal-screen-count
        v-icon.text--secondary.mr-1(small) mdi-television
        | {{ item.screenCount | numberWithCommas }}

    template(v-slot:item.startDate="{ item }")
      .deal-date
        | {{ validityPeriod(item) }}

    //- template(v-slot:item.createdOn="{ item }")
      | {{ singleDate(item.createdOn) }}

</template>

<script>
import _ from 'lodash'
import audienceApi from '@/services/audience.api'
import csv from '@/services/csv.service'
import userApi from '@/services/user.api'
import helpers from '@/services/helpers.service'
import confirmationDialog from '@/components/confirmationDialog.vue'
import dealService from '@/services/deal.service'

export default {
  props: ['isOverview', 'campaignDeals'],
  components: {
    confirmationDialog
  },
  data () {
    return {
      currentTab: 0,
      isDataLoading: true,
      deals: [],
      selected: [],
      marketplace: 'Public',
      selectAll: false,
      headers: [
        { text: 'Deal ID', value: 'code', sortable: false },
        { text: 'Publisher', value: 'publisher', sortable: false },
        { text: 'Name', value: 'name', width: '250px' },
        { text: 'Price', value: 'floorCPM', align: 'right' },
        { text: 'Venues', value: 'venueCount', align: 'right' },
        { text: 'Screens', value: 'screenCount', align: 'right' },
        { text: 'Date', value: 'startDate', width: '250px', align: 'right' }
        // { text: 'Created On', value: 'createdOn', align: 'right' }
      ],
      pagination: {
        sortBy: ['startDate'],
        sortDesc: [true],
        itemsPerPage: 50,
        itemsPerPageOptions: [50, 100, 150],
        page: 1
      },
      search: '',
      totalItems: 0,
      isDownloadingCsv: false,
      isArchiving: false,
      isUnarchiving: false,
      hasBuyerSeat: false,
      canSeeNewUi: false,
      dealLabel: 'marketplace'
    }
  },
  created () {
    this.fetchOrganisation()
    this.canSeeNewUi = this.$flags.canSeeUiRebrand.isEnabled()
    if (this.canSeeNewUi || !this.isOverview) {
      this.dealLabel = 'deals'
    }

    if (!this.isOverview) {
      this.pagination.itemsPerPage = 25
      this.pagination.itemsPerPageOptions = [25, 50, 100]
      this.selected = this.campaignDeals
    }
  },
  computed: {
    noDataText () {
      if (!this.deals.length && (this.search && this.search.length)) {
        return 'No deals match your search'
      } else {
        return 'No deals found'
      }
    },
    statusFilter () {
      return this.currentTab === 0 ? null : 'Archived'
    },
    organizationId () {
      return this.$store.getters['user/getOrganization'].id
    }
  },
  watch: {
    currentTab () {
      this.pagination.page = 1
      if (this.hasBuyerSeat) this.fetchDeals()
    },
    search: _.debounce(function () {
      this.pagination.page = 1
      if (this.hasBuyerSeat) this.fetchDeals()
    }, 450),
    pagination () {
      if (this.hasBuyerSeat) this.fetchDeals()
    },
    selected (newV) {
      if (!newV.length) this.selectAll = false
      if (!this.isOverview) {
        this.$emit('selectedDeals', newV)
      }
    }
  },
  methods: {

    fetchDeals () {
      this.isDataLoading = true
      if (this.hasBuyerSeat) {
        audienceApi.getDealsCount(this.pagination, { search: this.search, status: this.statusFilter }).then(count => {
          this.totalItems = count
        })
        audienceApi.getDeals(this.pagination, { search: this.search, status: this.statusFilter }).then(res => {
          this.deals = res
          this.isDataLoading = false
        })
      }
    },

    fetchOrganisation () {
      this.isDataLoading = true
      userApi.getOrganization(this.organizationId)
        .then(res => {
          this.rawOrg = JSON.parse(JSON.stringify(res))
          const hasSeatName = !!this.rawOrg.seatName
          this.hasBuyerSeat = hasSeatName
          this.isFetchingOrganization = false
          if (hasSeatName) this.fetchDeals()
          else this.isDataLoading = false
        }).catch(() => {
          this.$router.push({ name: 'Access Denied' })
        })
    },

    archiveDeals () {
      this.isArchiving = true
      if (!this.selectAll) {
        this.editDeals(this.selected, { dealStatus: 'Archived' })
          .then(() => {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: 'The selected deals have been successfully archived.'
            })
            this.selected = []
            this.pagination.page = 1
            this.fetchDeals()
          })
          .catch(() => {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: 'The selected deals have not been archived.'
            })
          })
          .finally(() => {
            this.isArchiving = false
          })
      }
    },

    unarchiveDeals () {
      this.isUnarchiving = true
      if (!this.selectAll) {
        this.editDeals(this.selected, { dealStatus: 'Active' })
          .then(() => {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: 'The selected deals have been successfully restored.'
            })
            this.selected = []
            this.pagination.page = 1
            this.fetchDeals()
          })
          .catch(() => {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: 'The selected deals have not been restored.'
            })
          })
          .finally(() => {
            this.isUnarchiving = false
          })
      }
    },

    editDeals (items, modifiedFields) {
      const promises = items.map(x => { audienceApi.editDeal(x.code, modifiedFields) })
      return Promise.all(promises)
    },

    downloadCsv () {
      this.isDownloadingCsv = true
      if (this.selectAll) {
        audienceApi.getDealsAsCSV(null, { search: this.search, status: this.statusFilter })
          .then((x) => {
            const formattedList = this.formatDealsForCSV(x)
            csv.csvExport(formattedList, 'deals')
            this.selected = []
          })
          .catch(() => {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: 'The download failed. Please try again.'
            })
          })
          .finally(() => {
            this.isDownloadingCsv = false
          })
      } else {
        const formattedList = this.formatDealsForCSV(this.selected)
        csv.csvExport(formattedList, 'deals')
        this.isDownloadingCsv = false
      }
    },

    createCampaign (deal = null) {
      if (deal) {
        this.$router.push({ path: 'create-campaign', query: { dealIds: deal.code } })
      } else {
        const ids = this.selected.map(x => x.code).join(',')
        this.$router.push({ path: 'create-campaign', query: { dealIds: ids } })
      }
    },

    // Helpers
    validityPeriod (deal) {
      const sd = helpers.customDateFormatting(deal.startDate, 'Do MMM, YYYY')
      const ed = helpers.customDateFormatting(deal.endDate, 'Do MMM, YYYY')
      return sd + ' - ' + ed
    },
    singleDate (date) {
      return helpers.customDateFormatting(date, 'Do MMM, YYYY')
    },
    formatDealsForCSV (arr) {
      return arr.map(x => {
        return {
          status: x.status,
          publisher: x.source.key,
          dealID: x.code,
          name: x.name,
          floorCPM: dealService.getFloorCPMsLabel(x.floorCPMs),
          venues: x.venueCount,
          screens: x.screenCount,
          startDate: x.startDate,
          endDate: x.endDate
          // createdOn: x.createdOn
        }
      })
    },
    getFloorCPMsLabel (floorCpms) {
      return dealService.getFloorCPMsLabel(floorCpms, '\n')
    }
  }
}
</script>
