import axios from 'axios'

var { VUE_APP_CAMPSITE_API_URL, VUE_APP_HUBSPOT_PORTAL_ID, VUE_APP_HUBSPOT_FORM_ID } = process.env

export default {
  getUser,
  editUser,
  getUserRoles,
  getAssignableRoles,
  assignRole,
  removeRole,
  getOrganization,
  getOrganizations,
  getAvailableOrganization,
  setUserActiveOrganization,
  getCurrencies,
  signup,
  hubspotNewContact,
  getIp,
  editOrganization,
  editOrganizationContact,
  createOrganizationContact,
  deleteOrganizationContact,
  createOrganizationSeat,
  updateOrganizationSeat,
  deleteOrganizationSeat,
  getAccountById,
  getOrganizationsAdv,
  getOrganizationsCount,
  getOrganizationsCsv,
  getDspPartnerOrganizations,
  createOrganization,
  getUsers,
  getUsersCount,
  removeUserFromOrganization,
  getUsersCsv,
  inviteUser,
  reinviteUser,
  acceptInvitation,
  getUserNotificationsConfig,
  setUserNotificationsConfig,
  addBillingContact,
  deleteBillingContact,
  getBAdsOrganizationFromPlatformId,
  getOrganizationPriorities,

  saveTheme,
  getTheme,
  deleteTheme,
  uploadLogo,
  getLogo,
  deleteLogo,
  getOrganizationFees,
  setOrganizationFees,
  getOrganizationDeals,
  addOrganizationDeal,
  deleteOrganizationDeal,
  getOrganizationApiKey,
  enableOrganizationApiKey,
  disableOrganizationApiKey
}

function getUser () {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/user'
  return axios.get(endpoint).then(response => response.data)
}

function editUser (user) {
  const url = VUE_APP_CAMPSITE_API_URL + '/users/' + user.id

  return axios.patch(url, user).then(response => response.data)
}

function getUserRoles (userId) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/users/' + userId + '/roles'
  return axios.get(endpoint).then(response => response.data)
}

function getAssignableRoles () {
  const url = VUE_APP_CAMPSITE_API_URL + '/roles/assignable'

  return axios.get(url).then(response => response.data)
}

function assignRole (data) {
  const url = VUE_APP_CAMPSITE_API_URL + '/roles/assign'

  return axios.patch(url, data).then(response => response.data)
}

function removeRole (data) {
  const url = VUE_APP_CAMPSITE_API_URL + '/roles/remove/' + data.roleId + '/user/' + data.userId + '/organization/' + data.organizationId

  return axios.delete(url).then(response => response.data)
}

function getOrganization (organizationId) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/organizations/' + organizationId
  return axios.get(endpoint).then(response => response.data)
}

function getOrganizations () {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/organizations/'
  return axios.get(endpoint).then(response => response.data)
}

function getAvailableOrganization () {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/user/availableorganizations/'
  return axios.get(endpoint).then(response => response.data)
}

function setUserActiveOrganization (orgId) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/user/active/' + orgId
  return axios.post(endpoint).then(response => response.data)
}

function getCurrencies () {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/currencies').then(res => res.data)
}

function signup (data) {
  return axios.post(VUE_APP_CAMPSITE_API_URL + '/signup', data).then(res => res.data)
}

function editOrganization (data) {
  return axios.patch(VUE_APP_CAMPSITE_API_URL + '/organizations/' + data.id, data).then(res => res.data)
}

function editOrganizationContact (organizationId, contact) {
  var url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + organizationId + '/contacts/' + contact.id
  return axios.patch(url, contact).then(response => response.data)
}

function createOrganizationContact (organizationId, contact) {
  var url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + organizationId + '/contacts'
  return axios.post(url, contact).then(response => response.data)
}

function deleteOrganizationContact (organizationId, contact) {
  var url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + organizationId + '/contacts/' + contact.id
  return axios.delete(url).then(response => response.data)
}

function createOrganizationSeat (organizationId) {
  var url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + organizationId + '/seat'
  return axios.post(url).then(response => response.data)
}

function updateOrganizationSeat (organizationId, seatId) {
  var url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + organizationId + '/seat'
  const body = {
    id: seatId
  }
  return axios.patch(url, body).then(response => response.data)
}

function deleteOrganizationSeat (organizationId) {
  var url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + organizationId + '/seat'
  return axios.delete(url).then(response => response.data)
}

function hubspotNewContact (fields, hutk = null) {
  const base = `https://api.hsforms.com/submissions/v3/integration/submit/${VUE_APP_HUBSPOT_PORTAL_ID}/${VUE_APP_HUBSPOT_FORM_ID}`
  const obj = { fields }
  if (hutk) obj.context = { hutk }
  return axios.post(base, obj)
}

function getIp () {
  return axios.get('https://ipapi.co/json/').then(res => res.data)
}

function getAccountById (accountId) {
  var url = VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId

  return axios.get(url).then(response => response.data)
}

function getOrganizationsAdv (params) {
  const url = VUE_APP_CAMPSITE_API_URL + '/organizations' + buildUrlQuery(params, false, true)
  return axios.get(url).then(response => response.data)
}

function getOrganizationsCount (params) {
  const url = VUE_APP_CAMPSITE_API_URL + '/organizations' + buildUrlQuery(params, true, true)
  return axios.head(url).then(response => parseInt(response.headers['x-count']) || 0)
}

function getOrganizationsCsv (params) {
  let url = VUE_APP_CAMPSITE_API_URL + '/organizations/export/'
  params.select = 'type,status,id,name,createdOn,address_addressLine1,address_city,address_postalCode,address_state_name,currency_code,industry,terms,paymentType,contacts_firstName,contacts_lastName,contacts_email,contacts_phone,billingAddress_addressLine1,billingAddress_addressLine2,billingAddress_city,billingAddress_state_name,billingAddress_country_name,billingAddress_postalCode'
  params.filters = params.filters.map(x => x.replace(/\//g, '_'))
  if (params.sort) params.sort = params.sort.replace(/\//g, '_')

  if (params.selectAll) {
    url += buildUrlQuery(params, true, true)
  } else {
    url += buildUrlQuery(params, false, true)
  }

  const config = {
    Accept: 'text/csv',
    'Content-Type': 'application/json'
  }

  return axios.request({ method: 'get', url: url, headers: config })
    .then(res => res.data)
}

function getDspPartnerOrganizations (params) {
  const url = VUE_APP_CAMPSITE_API_URL + '/dsppartnerorganizations' + buildUrlQuery(params, false, false)
  return axios.get(url).then(response => response.data)
}

function buildUrlQuery (params, isHead = false, isAgency = false) {
  let filters; let take; let sort; let select; let skip = null

  if (!isHead) {
    if (params.take) { take = 'take=' + params.take }
    if (params.skip) { skip = '$skip=' + params.skip }
  }

  if (params.filters.length) {
    filters = [...params.filters]
    filters = '$filter=' + filters.join(' and ')
  }

  if (params.select) { select = '$select=' + params.select }
  if (params.sort) { sort = '$orderby=' + params.sort }

  const opts = [filters, take, sort, skip, select]
  if (isAgency) { opts.push('isAgency=true') }
  return '?' + opts.filter(x => !!x).join('&')
}

function createOrganization (organization, isOwner) {
  var url = VUE_APP_CAMPSITE_API_URL + '/organizations' + (isOwner ? '?isOwner=true' : '')
  return axios.post(url, organization).then(response => response.data)
}

function getUsers (params) {
  let url = VUE_APP_CAMPSITE_API_URL + '/users'
  url += buildUrlQuery(params)

  return axios.get(url)
    .then(response => response.data)
}

function getUsersCount (params) {
  let url = VUE_APP_CAMPSITE_API_URL + '/users'
  url += buildUrlQuery(params, true)

  return axios.head(url)
    .then(response => parseInt(response.headers['x-count']) || 0)
}

function removeUserFromOrganization (userId, organizationId) {
  const url = VUE_APP_CAMPSITE_API_URL + '/users/' + userId + '/remove/' + organizationId
  return axios.post(url).then(response => response.data)
}

function getUsersCsv (params) {
  let url = VUE_APP_CAMPSITE_API_URL + '/users/export/'

  if (params.selectAll) {
    url += buildUrlQuery(params, true)
  } else {
    url += buildUrlQuery(params, false)
  }

  const config = {
    Accept: 'text/csv',
    'Content-Type': 'application/json'
  }

  return axios({ method: 'get', url: url, headers: config })
    .then(res => res.data)
}

function inviteUser (invitation) {
  const url = VUE_APP_CAMPSITE_API_URL + '/invitation'
  invitation.url = window.location.origin + '/invitation'

  return axios.post(url + '/invite', invitation).then(response => response.data)
}

function reinviteUser (invitation) {
  const url = VUE_APP_CAMPSITE_API_URL + '/invitation/reinvite'

  return axios.post(url, invitation).then(response => response.data)
}

function acceptInvitation (invitation) {
  const url = VUE_APP_CAMPSITE_API_URL + '/invitation/accept'

  return axios.post(url, invitation).then(response => response)
}

function getUserNotificationsConfig () {
  const url = VUE_APP_CAMPSITE_API_URL + '/notifications/configs'

  return axios.get(url).then(response => response.data)
}

function setUserNotificationsConfig (settings) {
  const url = VUE_APP_CAMPSITE_API_URL + '/notifications/configs'

  return axios.patch(url, settings).then(response => response)
}

function addBillingContact (email, orgId) {
  const url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + orgId + '/billingcontacts'
  return axios.post(url, { email }).then(res => res)
}

// function updateBillingContact(contact, orgId) {
//   let url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + orgId + '/billingcontacts'
//   return axios.patch(url, email). then(res => res)
// }

function deleteBillingContact (contactId, orgId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/billingcontacts/${contactId}`
  return axios.delete(url).then(res => res)
}

function getBAdsOrganizationFromPlatformId (platformId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/user/availableOrganizations?$filter=platformId eq '${platformId}'&$select=id`
  return axios.get(url).then(res => res.data)
}

function getOrganizationPriorities (orgId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/priorities`
  return axios.get(url).then(res => res.data)
}

// ===============
// Theme Endpoints
// ===============

function saveTheme (orgId, theme) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/theme`
  return axios.put(url, theme).then(res => res.data)
}

function getTheme (orgId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/theme`
  return axios.get(url).then(res => res.data)
}

function deleteTheme (orgId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/theme`
  axios.delete(url)
}

function uploadLogo (orgId, file) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/logo`
  const data = new FormData()
  data.append('file', file, file.name)
  return axios.put(url, data, { headers: { 'Content-Type': 'multipart/form-data' } })
}

async function getLogo (orgId, token, scopedToken) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/logo`
  try {
    const headers = {}
    if (token) headers.Authorization = token
    if (scopedToken) headers['scoped-token'] = scopedToken
    const res = await fetch(url, { headers })
    if (res.status !== 200) throw new Error('no logo')
    return await res.blob()
  } catch {
    return null
  }
}

function deleteLogo (orgId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/logo`
  return axios.delete(url)
}

function getOrganizationFees (orgId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/fees`
  return axios.get(url).then(res => res.data)
}

function setOrganizationFees (orgId, fees) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/fees`
  return axios.put(url, fees).then(res => res.data)
}

function getOrganizationDeals (organizationId) {
  const url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + organizationId + '/deals'
  return axios.get(url).then(response => response.data)
}

function addOrganizationDeal (organizationId, dealCode) {
  const url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + organizationId + '/deals/' + dealCode
  return axios.post(url).then(response => response.data)
}

function deleteOrganizationDeal (organizationId, dealCode) {
  const url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + organizationId + '/deals/' + dealCode
  return axios.delete(url)
}

function getOrganizationApiKey (orgId, test) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/keys/reporting`
  return axios.get(url)
    .then(res => res.data?.key)
    .catch(err => {
      const code = err?.response?.status
      if (code && code === 404) return null
      else throw new Error(err)
    })
}

function enableOrganizationApiKey (orgId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/keys/reporting`
  return axios.post(url)
}

function disableOrganizationApiKey (orgId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${orgId}/keys/reporting`
  return axios.delete(url)
}
