<template lang="pug">
div
  creatives-formats-table(
    :creatives-formats='requiredFormats'
    :assigned-creatives='assignedCreatives || []'
    :show-assigned='showAssigned')

  v-row.ma-0
    v-col(xs=12)
      .text-caption
        strong
          | Format:
        |  .jpg, .png, .mp4, .mov, .webm
      .text-caption
        strong
          | Duration:
        |  Up to 30 seconds
      .text-caption
        strong
          | File Size:
        |  Max 250 MB

      creative-footer-infos(:show-cropping-info='showCroppingInfo')
</template>
<script>
import creativesFormatsTable from '@/components/creatives/creativesFormatsTable'
import creativeFooterInfos from '@/components/creatives/creativeFooterInfos.vue'

export default {
  components: {
    creativesFormatsTable,
    creativeFooterInfos
  },
  props: {
    requiredFormats: {
      type: Array,
      default: () => []
    },
    assignedCreatives: {
      type: Array,
      default: () => []
    },
    showAssigned: {
      type: Boolean,
      default: () => true
    },
    showCroppingInfo: {
      type: Boolean,
      default: () => true
    }
  }
}
</script>
