<template lang="pug">
  v-dialog(max-width='500px' :fullscreen='$vuetify.breakpoint.smAndDown' v-model='isOpen')
    template(v-slot:activator='{on}')
      v-list-item#transfer-proposal-btn(v-on='on')
        v-list-item-icon.mr-4
          v-icon mdi-transfer-right
        v-list-item-title Transfer
      //- v-btn(color='white' :text="isInsideActionBar" v-on='on' :class="{'primary--text mr-2 my-1' : !isInsideActionBar}")
        v-icon.mr-2(v-if="isInsideActionBar" :small="$vuetify.breakpoint.mdAndUp") mdi-arrow-right-thick
        span Transfer Proposal
    v-card(:tile='$vuetify.breakpoint.smAndDown')
      v-card-title.dialog-title(style='white-space: normal;') Transfer {{ proposalLabel }}

      v-card-text(:style="$vuetify.breakpoint.xsOnly ? 'min-height: calc(100vh - 110px);' : ''")
        v-autocomplete(
          label='Select Organization'
          v-model='organizationId'
          :items='organizations'
          item-text='name'
          item-value='id'
          color='primary'
          :loading='fetchingOrganizations')

      v-card-actions
        v-spacer
        v-btn(text @click='isOpen = false') Cancel
        v-btn.transfer-btn(text color='primary' :disabled="!organizationId" @click='transfer') Transfer {{ proposalLabel }}
</template>

<script>
import helpersService from '@/services/helpers.service'
import userApi from '@/services/user.api'

export default {
  props: ['convertProposal', 'isInsideActionBar'],
  data () {
    return {
      isOpen: false,
      organizationId: null,
      organizations: [],
      fetchingOrganizations: true
    }
  },
  computed: {
    proposalLabel () {
      return this.$flags.canSeeUiRebrand.isEnabled() ? 'Plan' : 'Proposal'
    }
  },
  created () {
    if (!this.organizations.length) {
      this.fetchingOrganizations = true

      userApi.getOrganizationsAdv({ selectAll: true, filters: [] })
        .then(organizations => {
          this.organizations = organizations.sort(helpersService.sortByName)
          this.fetchingOrganizations = false
        })
    }
  },
  methods: {
    transfer () {
      this.isOpen = false

      if (this.organizationId) {
        this.$emit('transferProposal', this.organizationId)
      }
    }
  }
}
</script>
