export default {
  csvFormatter
}

function csvFormatter (line) {
  const formattedLine = {
    'Line Name': line.name,
    'Line External Id': line.externalId,
    'Est. Impressions': line.estimatedImpressions,
    'Number of Screens': line.numberOfFaces,
    'Number of Venues': line.numberOfVenues,
    'Start Date': line.startDate,
    'End Date': line.endDate,
    'Creative Type': line.targetedCreativeType,
    'Creative Format': line.targetedCreativeFormat,
    Publishers: line.targetedSuppliers,
    Currency: line.currency,
    'Min Price Range': line.minBidRange,
    'Max Price Range': line.maxBidRange,
    'Max Bid': line.maxBid,
    Budget: line.budget
  }

  return formattedLine
}
