import { render, staticRenderFns } from "./editAudienceDialog.vue?vue&type=template&id=35393ff8&scoped=true&lang=pug"
import script from "./editAudienceDialog.vue?vue&type=script&lang=js"
export * from "./editAudienceDialog.vue?vue&type=script&lang=js"
import style0 from "./editAudienceDialog.vue?vue&type=style&index=0&id=35393ff8&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35393ff8",
  null
  
)

export default component.exports