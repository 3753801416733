<template lang="pug">
//- v-expansion-panel
v-expansion-panel
  v-expansion-panel-header#panel-audience-header.text-h6(v-if='canSeeNewUi') Targets
  v-expansion-panel-header#panel-audience-header.text-h6(v-if='!canSeeNewUi') Audience
  v-expansion-panel-content.pb-4(ref='audience-panel-content')
    v-tabs(background-color='transparent' slider-color='primary' :value='tab')
        v-tab.px-6#location-tab(ripple color='primary lighten-4' :key='0' @click="$emit('switchTab', 0)")
          | Targets
        v-tab#poi-tab(ripple :key='1' @click="$emit('switchTab', 1)")
          | {{ 'Venues and Screens' }}

        v-spacer
        v-menu#extra-menu-create-campaign(v-if="parent === 'createCampaign' && (componentConfig.audiences.canSaveLoadAudience || componentConfig.audiences.isSupportShown)" offset-y left v-model="supportMenuOpen")
          template(v-slot:activator='{ on }')
            v-btn.mr-5.mt-2(small icon v-on='on')
              v-icon mdi-dots-vertical
          v-list
            v-list-item#load-audience-btn(v-if='componentConfig.audiences.canSaveLoadAudience')
              loadAudience(@pickedAudience='loadAudience')
            div(v-if='componentConfig.audiences.isSupportShown')
              v-divider
              v-list-item#geo-audience-link(href='https://intercom.help/broadsign-ads/en/articles/5705280-creating-my-first-campaign' target='_blank')
                v-list-item-title
                  | Support
                  v-icon(small ) mdi-launch

    v-divider
    v-tabs-items(:value='tab' )
      v-tab-item(:key='0')
        AudienceGeoTargets.px-3.pt-4.pb-2(
          :initializing="parentLoading"
          :value="audienceGeoTargetsArray"
          :search-func="search"
          :batch-search-func="batchSearch"
          :bulk-upload-batch-options="bulkUploadBatchOptions"
          :resolve-geo-json-func="resolveGeoJson"
          :max-bulk-upload-entries="10000"
          :active-index="activeGeoIndex"
          :search-label="geoSearchLabel"
          :postcode-label="postcodeLabel"
          :state-label="stateLabel"
          :unit="distanceUnit"
          :can-bulk-upload-screen-ids="true"
          @geoTargetSelected="onGeoTargetSelected"
          @input="onGeoTargetsChange"
          @action="trackGeoTargetsAction"
        )
        div.px-6
          div(v-if='parentLoading')
            v-layout.py-1(row wrap)
              v-flex.pb-0(xs12 lg3 xl4 text-xl-right justify-end )
                v-skeleton-loader.ma-3.text-xl-right(type='image' max-height="30")
              v-flex(xs12 lg9 xl8 text-md-left)
                v-skeleton-loader.my-3(type='sentences' max-width='90%')
                v-skeleton-loader.my-3(max-width='300' type='heading' min-width="65%")
                v-skeleton-loader.my-3(max-width='300' type='heading')
                v-skeleton-loader.my-3(max-width='300' type='heading' min-width="75%")
                v-skeleton-loader.my-3(max-width='300' type='heading' min-width="70%")
                v-skeleton-loader.my-3(max-width='300' type='heading' min-width="60%")

            v-layout.py-1(row wrap)
              v-flex.pb-0(xs12 lg3 xl4 text-xl-right justify-end )
                v-skeleton-loader.ma-3.text-xl-right(type='image' max-height="30")
              v-flex(xs12 lg9 xl8 text-md-left)
                v-skeleton-loader.my-3(type='sentences' max-width='90%')
                v-skeleton-loader.my-3(max-width='300' type='heading' min-width="65%")
                v-skeleton-loader.my-3(max-width='300' type='heading')
                v-skeleton-loader.my-3(max-width='300' type='heading' min-width="75%")
                v-skeleton-loader.my-3(max-width='300' type='heading' min-width="70%")
                v-skeleton-loader.my-3(max-width='300' type='heading' min-width="60%")

          div(v-if='!parentLoading')
            v-layout.py-1.targets-demographic(row wrap v-if='gotTargetsGroups && canSeeDemographics')
              v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
                .text-body-1.font-weight-bold.mr-3 Demographic
              v-flex(xs12 lg9 xl8 text-md-left)
                mobileSection(@storeUpdated='mobileSectionUpdated' :exchange='exchange')

            v-layout.py-1.targets-environment(row wrap v-show="$store.getters['audience/showEnvSection']")
              v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
                .text-body-1.font-weight-bold.mr-3 Environment
              v-flex(xs12 lg9 xl8 text-md-left)
                envsSection(@storeUpdated='envsSectionUpdated' v-if='exchange.key' :parent="parent")

            v-layout.py-1.targets-poi(row wrap)
              v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
                .text-body-1.font-weight-bold.mr-3 POI
              v-flex(xs12 lg9 xl8 text-md-left)
                PointsOfInterest(
                  label="Target venues close to points of interest"
                  :unit="distanceUnit"
                  :points-of-interest="pointsOfInterest"
                  :targeted-points-of-interest.sync="targetedPointsOfInterest"
                  @update:targetedPointsOfInterest="targetedPointsOfInterestUpdated"
                )

            v-layout.py-1.targets-screen(row wrap v-show="$store.getters['audience/showScreenSection']")
              v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
                .text-body-1.font-weight-bold.mr-3 Screen
              v-flex(xs12 lg9 xl8 text-md-left)
                screensSection(@storeUpdated='screensSectionUpdated')

            v-layout.py-1.audience-name(row wrap v-if="['addAudience', 'editAudience'].includes(parent)")
              v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
                .text-body-1.font-weight-bold.mr-3 Name
              v-flex(xs12 lg9 xl8 text-md-left)
                v-text-field(
                  id='audience-name'
                  :label='(canSeeNewUi ? "Package" : "Audience") + " Name"'
                  :placeholder='lineNamePlaceholder'
                  :value='lineName'
                  @input='lineNameChanged')

        v-card-actions#save-as-audience-btn.px-6.pt-3(v-if='!isOnAuctionPackagePage && componentConfig.audiences.canSaveLoadAudience')
          v-layout.pb-4.pb-md-0(row wrap align-center)
            v-flex(v-if="parent === 'createCampaign'" md8 xs12)
              v-checkbox.mt-0.mb-2(
                v-model='saveAsAudienceLocal'
                :label='"Save as " + (canSeeNewUi ? "Package" : "Audience")'
                hide-details
                color='primary'
                @change='saveAsAudienceChanged'
                )
      v-tab-item(:key='1')
        forecast-inventory(
          :loading='isForecastVenuesLoading'
          :downloadingInventory='downloadingInventory'
          :items='venues'
          :total-items='numberOfVenues'
          :page='pagination.page'
          :has-more-items='pagination.hasMorePages'
          :tab="tab"
          updateVenueInclusionEnabled='true'
          @updateVenueInclusion='updateVenueInclusion'
          @inventoryListChanged="inventoryListChanged"
          @downloadInventory='downloadInventory'
          style='min-height: 65vh;')
</template>

<script>
import envsSection from '@/components/audience/audience.environments.vue'
import { AudienceGeoTargets, PointsOfInterest } from '@ayudasystems/campaign-targeting'
import mobileSection from '@/components/audience/audience.mobileTargets.vue'
import screensSection from '@/components/audience/audience.screens.vue'
import forecastInventory from '@/components/forecast.inventory.vue'
import loadAudience from '@/components/loadAudience.vue'
import geoService from '@/services/geo.service'
import componentConfigService from '@/services/componentConfig'
import defaultExchangeValues from '@/services/defaultExchangeValues'
import _ from 'lodash'
import tracking from '@/services/tracking'

export default {
  components: {
    envsSection,
    AudienceGeoTargets,
    PointsOfInterest,
    mobileSection,
    screensSection,
    forecastInventory,
    loadAudience
  },
  props: ['isOnAuctionPackagePage', 'parent', 'parentLoading', 'tab', 'downloadingInventory'],
  async created () {
    this.canSeeNewUi = this.$flags.canSeeUiRebrand.isEnabled()
    if (this.canSeeNewUi) {
      this.packageLabel = 'package'
    }

    await this.setPointsOfInterest()
  },
  data () {
    return {
      saveAsAudienceLocal: false,
      supportMenuOpen: false,
      packageLabel: 'audience',
      canSeeNewUi: false,

      pointsOfInterest: [],
      targetedPointsOfInterest: [],
      syncPoisFromStore: true,

      bulkUploadBatchOptions: {
        inventory: {
          batchSize: 100,
          numberOfParallelCalls: 10
        },
        geocoded: {
          batchSize: 10,
          numberOfParallelCalls: 20
        },
        address: {
          batchSize: 5,
          numberOfParallelCalls: 20
        }
      }
    }
  },
  watch: {
    parentLoading (newV) {
      if (!newV) this.$store.commit('audience/saveInitialTargeting', this.targeting)
    },
    poiGeoTargets () {
      if (this.syncPoisFromStore) this.setTargetedPointsOfInterestFromStore()
    }
  },
  computed: {
    canSeeDemographics () {
      const exchangeInfo = defaultExchangeValues.getAllDefaultValues().find(x => x.market === this.exchange.key)
      return this.$flags.canSeeDemographics.isEnabled() && exchangeInfo.canSeeAudienceDemographics
    },
    lineName () {
      return this.$store.getters['createCampaign/getLineName']
    },
    saveAsAudience () {
      return this.$store.getters['createCampaign/getSaveAsAudience']
    },
    exchange () {
      return this.isOnAuctionPackagePage ? this.$store.getters['auctionPackage/getExchange'] : this.$store.getters['createCampaign/getExchange']
    },
    lineNameClasses () {
      return this.parent === 'createCampaign' ? 'md8 xs12' : 'md12 xs12'
    },
    gotTargetsGroups () {
      var targetsGroups = this.$store.getters['audience/activeMobileTargetGroups'] || []
      return targetsGroups.length > 0
    },
    lineNamePlaceholder () {
      const market = this.$store.getters['general/marketDefaultValues'].lineName
      return market || ''
    },
    forecastVenues () {
      var forecastVenues = this.$store.getters['audience/forecastVenues']
      if (forecastVenues) return forecastVenues.venues
      else return []
    },
    numberOfVenues () {
      return this.$store.getters['audience/forecast'].inventory.numberOfVenues
    },
    isForecastVenuesLoading () {
      return this.$store.getters['audience/forecastVenuesLoading']
    },
    pagination () {
      return this.$store.getters['audience/getPagination']
    },
    poiGeoTargets () {
      return this.selectedGeoTargets.filter(x => x.type === 'point_of_interest')
    },
    nonPoiGeoTargets () {
      return this.selectedGeoTargets.filter(x => x.type !== 'point_of_interest')
    },
    audienceGeoTargetsArray () {
      return this.nonPoiGeoTargets.map(x => {
        return {
          isIncluded: x.isIncluded,
          radius: x.radius,
          geolocation: {
            label: x.label,
            value: x.value,
            type: x.type,
            geography: {
              latitude: x.geography?.latitude,
              longitude: x.geography?.longitude,
              geoJSON: x.geoJSON ?? x.geography?.geoJSON,
              geoJSONFileUrl: x.geoJSONFileUrl ?? x.geography?.geoJSONFileUrl
            }
          }
        }
      })
    },
    selectedGeoTargets: {
      get () {
        return this.$store.getters['audience/selectedGeoTargets']
      },
      set (value) {
        value = value.filter(x => this.hasValidCoordinates(x) || x.type !== 'address')
        this.$store.dispatch('audience/updateGeoTargetsAction', value)
        const venueOnly = value.length === 1 && value[0].type === 'venue'
        this.$emit('updateForecast', !venueOnly)
      }
    },
    venues () {
      return this.forecastVenues.map(x => {
        const venueObjInStore = this.selectedGeoTargets.find(y => y.value === x.id.toString())
        const isIncluded = !venueObjInStore || venueObjInStore.isIncluded
        return { ...x, isIncluded }
      })
    },
    targeting () {
      return this.$store.getters['audience/selectedTargets']
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    marketValues () {
      return this.$store.getters['general/marketValueFromExchange'](this.exchange.key)
    },
    postcodeLabel () {
      return this.marketValues?.postcodeLabel
    },
    stateLabel () {
      return this.marketValues?.stateLabel
    },
    distanceUnit () {
      return this.marketValues?.distanceUnit
    },
    geoSearchLabel () {
      return `Add ${this.stateLabel?.toLowerCase()}, cities, ${this.postcodeLabel?.toLowerCase()}, addresses, lat/long, or venues`
    },
    activeGeoIndex () {
      const index = this.$store.getters['audience/getFocusedGeoInfo'].index
      const selectedGeoTarget = this.selectedGeoTargets[index]
      return index === -1 ? index : this.audienceGeoTargetsArray.findIndex(g => this.isSameGeoTarget(selectedGeoTarget, g))
    }
  },
  methods: {
    hasValidCoordinates (x) {
      return x.type === 'address' && x.geography && x.geography.latitude !== 0 && x.geography.longitude !== 0
    },
    saveAsAudienceChanged (val) {
      this.$store.commit('createCampaign/setSaveAsAudience', val)
    },

    async loadAudience (data) {
      await this.$store.dispatch('audience/loadAudience', data)
      this.$emit('updateForecast')
      this.supportMenuOpen = false
    },

    poiSectionUpdated () {
      this.$emit('updateForecast')
    },

    mobileSectionUpdated () {
      this.$emit('updateForecast')
    },

    envsSectionUpdated () {
      this.$emit('updateForecast')
    },

    screensSectionUpdated () {
      this.$emit('updateForecast')
    },

    lineNameChanged: _.debounce(function (lineName = null) {
      if (lineName) {
        this.$store.commit('createCampaign/setLineName', lineName)
      }
    }, 300),

    inventoryListChanged (pagination) {
      this.$emit('inventoryListChanged', pagination)
    },
    updateVenueInclusion (venue) {
      this.$store.dispatch('audience/updateVenueInclusion', venue)
    },

    downloadInventory (scope) {
      this.$emit('downloadInventory', scope)
    },
    search (val, count, locationType) {
      return geoService.search(val, count, locationType, this.exchange.key)
    },
    batchSearch (queries, locationType) {
      return geoService.batchSearch(queries, locationType, this.exchange.key)
        .then(res => {
          const selectedGeoTargetsByValue = new Set(this.selectedGeoTargets.map(y => y.value))
          const selectedGeoTargetsByLabel = new Set(this.selectedGeoTargets.map(y => y.label))
          const newResults = {}
          Object.entries(res).forEach(([idx, result]) => {
            const isDuplicate = result.value
              ? selectedGeoTargetsByValue.has(result.value)
              : selectedGeoTargetsByLabel.has(result.label)
            if (!isDuplicate) newResults[idx] = result
          })
          return newResults
        })
    },
    onGeoTargetsChange (geoTargetsArray) {
      this.$store.commit('audience/setFocusedGeoInfo', { index: -1, centerMap: false })

      const unmappedGeoTargets = geoTargetsArray.map(this.convertSelectedGeoTargets)
      this.selectedGeoTargets = unmappedGeoTargets.concat(this.poiGeoTargets)
    },
    convertSelectedGeoTargets (geoTarget) {
      return {
        isIncluded: geoTarget.isIncluded,
        radius: geoTarget.radius,
        type: geoTarget.geolocation.type,
        label: geoTarget.geolocation.label,
        value: geoTarget.geolocation.value,
        geoJSONFileUrl: geoTarget.geolocation.geography.geoJSONFileUrl,
        geography: {
          latitude: geoTarget.geolocation.geography.latitude,
          longitude: geoTarget.geolocation.geography.longitude,
          geoJSON: geoTarget.geolocation.geography.geoJSON
        }
      }
    },
    onGeoTargetSelected (geoTarget, mapIndex) {
      const storeIndex = this.selectedGeoTargets.findIndex(g => this.isSameGeoTarget(g, geoTarget))
      if (storeIndex !== -1) {
        const newStoreIndex = mapIndex === this.activeGeoIndex ? -1 : storeIndex
        this.$store.commit('audience/setFocusedGeoInfo', { index: newStoreIndex, centerMap: true })
      }

      this.$root.$emit('highlightElementOnMap', this.convertSelectedGeoTargets(geoTarget))
    },
    isSameGeoTarget (geoTargetFromStore, geoTargetFromMap) {
      return geoTargetFromStore.value === geoTargetFromMap.geolocation.value &&
        geoTargetFromStore.label === geoTargetFromMap.geolocation.label &&
        geoTargetFromStore.radius === geoTargetFromMap.radius &&
        geoTargetFromStore.isIncluded === geoTargetFromMap.isIncluded
    },
    trackGeoTargetsAction (actionName, geoTarget) {
      let eventName = actionName

      if (actionName === 'locationInclusionUpdated') {
        if (geoTarget.isIncluded) return
        else {
          eventName = 'locationExcluded'
        }
      }

      const label = `${geoTarget.geolocation.label} (${geoTarget.geolocation.type})`
      tracking.sendEvent(['ga'], eventName, { label })
    },
    async resolveGeoJson (geographyId, geoJSONFileUrl) {
      return await geoService.resolveGeoJson(geographyId, geoJSONFileUrl)
    },
    async setPointsOfInterest () {
      try {
        this.pointsOfInterest = await geoService.fetchPois()
      } catch (e) {
        console.error('Could not get points of interest', e)
      }
    },
    setTargetedPointsOfInterestFromStore () {
      this.targetedPointsOfInterest = this.poiGeoTargets.map(geoTarget => {
        return {
          pointOfInterest: {
            id: parseInt(geoTarget.data.type.id),
            name: geoTarget.data.type.name
          },
          radius: geoTarget.data.radius,
          isIncluded: geoTarget.isIncluded
        }
      })
    },
    targetedPointsOfInterestUpdated () {
      this.syncPoisFromStore = false

      const targetedPoisAsGeoTargets = this.targetedPointsOfInterest.map(targetedPoi => {
        return {
          type: 'point_of_interest',
          isIncluded: targetedPoi.isIncluded,
          data: {
            radius: targetedPoi.radius,
            type: {
              id: targetedPoi.pointOfInterest.id.toString(),
              name: targetedPoi.pointOfInterest.name
            }
          }
        }
      })

      this.$store.dispatch('audience/updateGeoTargetsAction', [...this.nonPoiGeoTargets, ...targetedPoisAsGeoTargets])
      this.poiSectionUpdated()
    }
  }
}
</script>

<style scoped lang="stylus">
  .offsetmap
    margin-top:-86px
  .sticky-column
    position: -webkit-sticky
    position: sticky
    top: 45px
  @media screen and (max-width: 599px)
    .offsetmap
      margin-top: 0px
  >>> .v-expansion-panel-content__wrap
    padding-left: 0px
    padding-right: 0px
    padding-bottom: 0px
</style>
