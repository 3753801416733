<template lang="pug">
div
  v-scroll-y-reverse-transition
    v-toolbar(color='secondary lighten-1' width='100%' dark dense flat :class="{'toolbar-touch': $vuetify.breakpoint.smAndDown, 'toolbar-desktop': $vuetify.breakpoint.mdAndUp }" v-show='selected.length')
      span.hidden-sm-and-down
        v-toolbar-title.text-subtitle-1(v-if='!selectAll') {{ assembleActionToolbarText(selected.length, dimensionName) }}
        v-toolbar-title.text-subtitle-1(v-if='selected.length === tableData.length && selectAll') All {{ totalItems }} {{ dimensionName }} are selected

      span.hidden-md-and-up
        v-chip.ma-1(v-if='!selectAll && selected.length !== totalItems' outlined color='white' small)
          | {{ selected.length }}
        v-chip.ma-1(v-if='selected.length === totalItems || selectAll' dark color='white' class="secondary--text" small)
          | {{ totalItems }}

      v-btn.ml-2(text outlined small dark v-if='selected.length === tableData.length && tableData.length < totalItems && !selectAll' @click='selectAll = true') Select all
        span.hidden-sm-and-down
          b &nbsp;{{ totalItems }}&nbsp;
          | {{ dimensionName }}

      v-toolbar-items.ml-3
        v-btn(:icon="$vuetify.breakpoint.smAndDown" :text="$vuetify.breakpoint.mdAndUp" @click='exportCsvBtnClicked()')
          v-icon(:small="$vuetify.breakpoint.mdAndUp") mdi-download
          span.ml-2.hidden-sm-and-down Export CSV
        confirmationDialog(v-if="currentDimension === 'lines'" @confirmAction='duplicate' type="line" action='duplicate' :numberOfEntries='selected.length')
        confirmationDialog(v-if="currentDimension === 'lines'" @confirmAction='archive' type="line" action="archive" :numberOfEntries='selected.length')

      v-spacer
      v-btn.ml-3(icon @click='selected = []')
          v-icon mdi-close

  v-data-table.cs-data-table(
    :headers='headers'
    :items='tableData'
    :loading='tableLoading'
    :options.sync='pagination'
    v-model='selected'
    item-key='key'
    :footer-props="{itemsPerPageOptions: [50, 100, 150, 300, 500]}"
    show-select
    :dense="$vuetify.breakpoint.xsOnly"
    :class="{'colorTransparent': $vuetify.breakpoint.xsOnly}"
    must-sort
    :no-data-text='noDataText'
    no-results-text=''
    :server-items-length='totalItems'
  )

    //---------------
    //- REPEATED
    //---------------
    template(v-slot:header.impressions='{ header }')
      v-tooltip(top dark max-width='300' color='secondary')
        template(v-slot:activator='{on}')
          span(v-on='on')
            | {{ header.text }}
        span.text-caption(v-if='supportDefinitions' v-html="supportDefinitions.find(x => x.name === 'Impression').description")

    template(v-slot:header.adsServed='{ header }')
      v-tooltip(top dark max-width='300' color='secondary')
        template(v-slot:activator='{on}')
          span(v-on='on')
            | {{ header.text }}
        span.text-caption(v-if='supportDefinitions' v-html="supportDefinitions.find(x => x.name === 'Ad Served').description")

    template(slot='no-data')
      p.text-center.mb-0 {{ noDataText }}

    template(v-slot:item.spent='{ item }')
      spentVsBudgetProgressBar(
        v-if='currentDimensionIs(["lines"])'
        :item="item"
        :min-width="190"
        warning-msg="This Line Order requires your attention"
      )
      .text-right(v-if='!currentDimensionIs(["lines"])') {{ formatMoney(item.spent) }}

    template(v-slot:item.impressions='{ item }')
      .text-md-right.text-xs-left {{ item.impressions | numberWithCommas }}

    template(v-slot:item.avgCPM='{ item }')
      .text-md-right.text-xs-left {{ formatMoney(item.avgCPM) }}

    template(v-slot:item.adsServed='{ item }')
      .text-md-right.text-xs-left {{ item.adsServed | numberWithCommas }}

    template(v-slot:item.switchStatus='{ item }')
      v-switch.mt-0.pt-0.booking-status-switch-desktop(
        v-if='currentDimensionIs(["lines"])'
        :input-value='item.bookingStatus === "Active"'
        hide-details
        color='primary'
        @change='lineStatusChanged(item)'
      )
      v-switch.mt-0.pt-0(
        v-if='currentDimensionIs(["venues"]) && currentInstanceIs("line")'
        :input-value="venueStatus(item)"
        hide-details
        height='20'
        color='primary'
        @change='venueStatusChanged(item)'
      )

      v-switch.mt-0.pt-0(
        v-if='currentDimensionIs(["screens"]) && currentInstanceIs("venue")'
        :input-value="screenStatus(item)"
        hide-details
        height='20'
        color='primary'
        @change='screenStatusChanged(item)'
      )
    template(v-slot:item.name='{ item }')
      .info--text(class="grey--text") {{this.lineData.priority}}

    template(v-slot:item.name='{ item }')
      div(v-if='currentDimensionIs(["lines"])' style='min-width:220px; position:relative;')
        v-hover(ref='line-hover-menu-ref' v-slot='{ hover }')
          .line-hover-menu-wrapper
            router-link.line-link.info--text(:to="{ name: 'report-line', params: { campaignId: campaignId, lineId: item.key, isForAdServer } }") {{ item.name }}
            .text-caption(class="grey--text")
              | {{ item.startDate | date('MMM Do, YYYY') }} - {{ item.endDate | date('MMM Do, YYYY') }}

            .line-hover-menu.d-flex(v-if='hover' style='position:absolute; right:0px; top:50%; transform: translate(0,-50%); background-color:#eeeeee;')
              v-tooltip(top)
                template(v-slot:activator='{ on, attrs }')
                  v-btn.troubleshooting-tool(v-if='troubleshootingToolVisible' icon color='grey' @click.stop='openTroubleshootingTool(item)' v-bind='attrs' v-on='on')
                    v-icon mdi-wrench
                span.caption Troubleshoot Line

      div(v-if='currentDimensionIs(["venues"])')
        venuePopoverDisplay(v-if='!currentInstanceIs("line")' :venueDetails="venuePopoverInfos(item)")

        //- can only link to "Venue Report" from "Line Report" (need "lineId" to build "router-link")
        router-link.info--text(
          v-if='currentInstanceIs("line")'
          :to="{ name: 'report-venue', params: { campaignId: campaignId, lineId: lineId, venueId: item.key } }"
        )
          venuePopoverDisplay(:venueDetails="venuePopoverInfos(item)")
        br
        .text-caption.grey--text {{ venueAddress(item) }}

      div(v-if='currentDimensionIs(["screens"])')
        span(v-if='!currentInstanceIs("venue")') {{ item.name }}
        //- can only link to "Screen Report" from "Venue Report" (need "venueId" to build "router-link")
        router-link.info--text(
          v-if='currentInstanceIs("venue")'
          :to="{ name: 'report-screen', params: { campaignId: campaignId, lineId: lineId, venueId: venueId, screenId: item.key } }"
        ) {{ item.name }}

      div(v-if='!(currentDimensionIs(["lines", "venues", "screens"]))')
        | {{ item.name }}

    //---------------
    //- LINES
    //---------------
    template(v-if='currentDimensionIs(["lines"])' v-slot:item.editLine='{ item }')
      v-btn(icon color="grey" @click="openEditLineDialog(item)")
        v-icon mdi-pencil

    template(v-if='currentDimensionIs(["lines"])' v-slot:item.effectiveStatus='{ item }')
      v-tooltip(top dark color='secondary' max-width='300' :disabled='!lineStatusTooltip(item)')
        template(v-slot:activator='{on}')
          v-chip.text-uppercase(v-on='on' small outlined :color='lineStatusColor(item)')
            v-icon.mr-1.ml-0(:color='lineStatusColor(item)' small) {{ lineStatusIcon(item) }}
            small.text--primary(style='white-space: nowrap;') {{ lineStatusLabel(item) }}
        span(v-html='lineStatusTooltip(item)')

    template(v-slot:item.priority='{ item }')
      .text--secondary.mr-1(v-if="item.priority") {{ item.priority.name }}

    template(v-if='currentDimensionIs(["lines"])' v-slot:item.maxbid='{ item }')
      .text-md-right.text-xs-left(style="min-width:80px;"
        v-if='!componentConfig.campaigns.isCPMFixed') {{ formatMoney(item.maxCpm) }} CPM
      .text-md-right.text-xs-left(style="min-width:80px;" v-else) {{ formatMoney(item.maxCpm) }}

    template(v-if='currentDimensionIs(["lines"])' v-slot:item.deals='{ item }')
      dealsHoverDialog(
        v-if='item.deals.length'
        :deals='item.deals'
        :line-id='item.key'
        @openEditLineDialog="openEditLineDialog(item, 'deals')")

    template(v-if='currentDimensionIs(["lines"])' v-slot:item.creatives='{ item }')
      .text-right.d-block(style="min-width:70px;")
        creativesHoverDialog(
          v-if='!assignmentsLoading'
          :account='account',
          :campaignId='campaignId',
          :line='item'
          :line-assignments='lineAssignments(item.id)'
          @openEditLineDialog="openEditLineDialog(item, 'creatives')"
        )

    template(v-if='!currentDimensionIs(["lines"])' v-slot:item.numberOfScreens='{ item }').text-left
      v-icon.text--secondary.mr-1 mdi-television
      |  {{ item.numberOfScreens }}

    //---------------
    //- VENUES & SCREENS ONLY
    //---------------
    template(v-if='currentDimensionIs(["venues", "screens"])' v-slot:item.locationType='{ item }')
      .text-left {{ item.locationType }}

    //---------------
    //- CREATIVES
    //---------------
    template(v-if='currentDimensionIs(["creatives"])' v-slot:item.preview='{ item }')
      creativeImage(:creative='item')

    //--------------------------------------------
    //- ENVIRONMENTS, COUNTRIES, STATES, OR CITIES
    //--------------------------------------------
    template(v-if='currentDimensionIs(["countries", "states", "cities", "environments"]) && componentConfig.campaigns.isVenuesShown' v-slot:item.numberOfVenues='{ item }').text-left
        v-icon.text--secondary mdi-map-marker
        |  {{ item.numberOfVenues }}

    //--------------------
    //- Mobile Start Here
    //--------------------
    template(v-slot:item="{ item, select, isSelected }" v-if='$vuetify.breakpoint.xsOnly' )
      //---------------
      //- Mobile Lines
      //---------------
      div(v-if='currentDimensionIs(["lines"])' )
        v-card.ma-3.px-3.py-1
          v-layout.my-2.align-center.justify-center
            v-flex.text-left.d-flex(xs3)
              v-checkbox.mt-0.pt-0(@click.stop="isSelected? select(false) : select(true)" :value="isSelected" hide-details color='primary')
              v-switch.mt-0.pt-0.booking-status-switch-mobile(
                v-if='currentDimensionIs(["lines"])'
                :input-value='item.bookingStatus === "Active"'
                hide-details
                color='primary'
                @change='lineStatusChanged(item)'
              )
            v-flex(xs5)
              v-chip.text-uppercase(small outlined :color='lineStatusColor(item)')
                v-icon.mr-1.ml-0(:color='lineStatusColor(item)' small) {{ lineStatusIcon(item) }}
                small.text--primary(style='white-space: nowrap;') {{ lineStatusLabel(item) }}
            v-flex.text-right(xs4)
              v-btn(icon color="grey" @click="openEditLineDialog(item)")
                v-icon mdi-pencil
          div(v-if='componentConfig.campaigns.isLinePriorityShown')
            .text-caption.font-weight-bold.priority Priority: {{ item.priority ? item.priority.name : '' }}
          div(v-if='currentDimensionIs(["lines"])' style='min-width:220px;')
            .d-block(style='word-break: break-word;')
              router-link.line-link-mobile.mt-0.info--text(:to="{ name: 'report-line', params: { campaignId: campaignId, lineId: item.key, isForAdServer } }") {{ item.name }}
              .text-caption(class="grey--text")
                | {{ item.startDate | date('MMM Do, YYYY') }} - {{ item.endDate | date('MMM Do, YYYY') }}

          spentVsBudgetProgressBar.my-2(
            v-if='currentDimensionIs(["lines"])'
            :item="item"
            :min-width="220"
            warning-msg="This Line Order requires your attention"
          )
          v-layout.my-2.align-center.justify-center
            v-flex(xs6)
              v-icon.text--secondary(medium) mdi-account-multiple
              span.text-body-2  {{ item.impressions | numberWithCommas }}
            v-flex(xs6)
              v-icon.text--secondary(medium) mdi-play-circle
              span.text-body-2  {{ item.adsServed | numberWithCommas }}
          v-layout.align-center.justify-center
            v-flex(xs6 v-if='componentConfig.campaigns.isECPMShown')
              span.text-caption.font-weight-bold.eCPM eCPM:
              span.text-body-2 {{ formatMoney(item.avgCPM) }}
            v-flex(xs6)
              span(v-if='!componentConfig.campaigns.isCPMFixed').text-caption.font-weight-bold.max-bid Max Bid:
              span(v-else).text-caption.font-weight-bold.fixed-CPM Fixed CPM:
              span.text-body-2 {{ formatMoney(item.maxCpm) }}
          v-layout.align-center.justify-center
            v-flex(xs6)
              span.text-caption.font-weight-bold Assigned creatives:
            v-flex(xs6)
              .text-caption
                creativesHoverDialog(
                  v-if='!assignmentsLoading'
                  :account='account',
                  :campaignId='campaignId',
                  :line='item'
                  :line-assignments='lineAssignments(item.id)'
                  @openEditLineDialog="openEditLineDialog(item, 'creatives')"
                )
          v-layout.mb-2.align-center.justify-center(v-if='item.deals.length && componentConfig.campaigns.isDealsShown')
            v-flex(xs6)
              span.text-caption.font-weight-bold.deals Deals:
            v-flex(xs6)
              .text-caption
                dealsHoverDialog(:deals='item.deals' :line-id='item.key')
      //---------------
      //- Mobile Venues
      //---------------
      div(v-if='currentDimensionIs(["venues"])')
        v-card.ma-3.px-3.py-1
          v-layout.my-2.align-center.justify-center
            v-flex.text-left.d-flex(xs3)
              v-checkbox.mt-0.pt-0(@click.stop="isSelected? select(false) : select(true)" :value="isSelected" hide-details color='primary')
              v-switch.mt-0.pt-0(
                v-if='currentDimensionIs(["venues"]) && currentInstanceIs("line")'
                :input-value="venueStatus(item)"
                hide-details
                height='20'
                color='primary'
                @change='venueStatusChanged(item)'
              )
            v-flex.text-left.d-flex(xs9)
          div
            .text-overline {{ item.locationType }}
            div.d-block(style='word-break: break-word;')
              venuePopoverDisplay(v-if='!currentInstanceIs("line")' :venueDetails="venuePopoverInfos(item)")
              //- can only link to "Venue Report" from "Line Report" (need "lineId" to build "router-link")
              router-link.info--text(
                v-if='currentInstanceIs("line")'
                :to="{ name: 'report-venue', params: { campaignId: campaignId, lineId: lineId, venueId: item.key } }"
              )
                venuePopoverDisplay(:venueDetails="venuePopoverInfos(item)")
              div.text-caption.grey--text {{ venueAddress(item) }}, {{ item.city }}
          v-layout.my-2.align-center.justify-center
            v-flex(xs6)
              v-icon.text--secondary(medium) mdi-account-multiple
              span.text-body-2  {{ item.impressions | numberWithCommas }}
            v-flex(xs6)
              v-icon.text--secondary(medium) mdi-play-circle
              span.text-body-2  {{ item.adsServed | numberWithCommas }}
          v-layout.mb-2.align-center.justify-center
            v-flex(xs6)
              span.text-caption.font-weight-bold eCPM:
              span.text-body-2 {{ formatMoney(item.avgCPM) }}
            v-flex(xs6)
              span.text-caption.font-weight-bold Spent:
              span.text-body-2 {{ formatMoney(item.spent) }}

      //---------------
      //- Mobile Screens
      //---------------
      div(v-if='currentDimensionIs(["screens"])')
        v-card.ma-3.px-3.py-1
          v-layout.my-2.align-center.justify-center
            v-flex.text-left.d-flex(xs3)
              v-checkbox.mt-0.pt-0(@click.stop="isSelected? select(false) : select(true)" :value="isSelected" hide-details color='primary')
              v-switch.mt-0.pt-0(
                v-if='currentDimensionIs(["screens"]) && currentInstanceIs("venue")'
                :input-value="screenStatus(item)"
                hide-details
                height='20'
                color='primary'
                @change='screenStatusChanged(item)'
              )
            v-flex.text-left.d-flex(xs9)
          div
            .text-overline {{ item.locationType }}
            div.d-block(style='word-break: break-word;')
              div(v-if='currentDimensionIs(["screens"])')
                span(v-if='!currentInstanceIs("venue")') {{ item.name }}
                //- can only link to "Screen Report" from "Venue Report" (need "venueId" to build "router-link")
                router-link.info--text(
                  v-if='currentInstanceIs("venue")'
                  :to="{ name: 'report-screen', params: { campaignId: campaignId, lineId: lineId, venueId: venueId, screenId: item.key } }"
                ) {{ item.name }}

              div.text-caption.grey--text {{ venueAddress(item) }}, {{ item.city }}
          v-layout.my-2.align-center.justify-center
            v-flex(xs6)
              v-icon.text--secondary(medium) mdi-account-multiple
              span.text-body-2  {{ item.impressions | numberWithCommas }}
            v-flex(xs6)
              v-icon.text--secondary(medium) mdi-play-circle
              span.text-body-2  {{ item.adsServed | numberWithCommas }}
          v-layout.mb-2.align-center.justify-center
            v-flex(xs6 v-if='componentConfig.campaigns.isECPMShown')
              span.text-caption.font-weight-bold.eCPM eCPM:
              span.text-body-2 {{ formatMoney(item.avgCPM) }}
            v-flex(xs6)
              span.text-caption.font-weight-bold Spent:
              span.text-body-2 {{ formatMoney(item.spent) }}

      //---------------
      //- Mobile Creative
      //---------------
      div(v-if='currentDimensionIs(["creatives"])')
        v-card.ma-3.px-3.py-1
          v-layout.my-2.align-center.justify-center
            v-flex.text-left.d-flex(xs3)
              v-checkbox.mt-0.pt-0(@click.stop="isSelected? select(false) : select(true)" :value="isSelected" hide-details color='primary')
            v-flex.text-left.d-flex(xs9)
          v-layout.my-2.align-start
            v-flex(xs4)
              creativeImage(:creative='item')
            v-flex.pl-3(xs8)
              div.d-block(style='word-break: break-word;')
                | {{ item.name }}

          v-layout.my-2.align-center.justify-center
            v-flex(xs6)
              v-icon.text--secondary(medium) mdi-account-multiple
              span.text-body-2  {{ item.impressions | numberWithCommas }}
            v-flex(xs6)
              v-icon.text--secondary(medium) mdi-play-circle
              span.text-body-2  {{ item.adsServed | numberWithCommas }}
          v-layout.mb-2.align-center.justify-center
            v-flex(xs6)
              span.text-caption.font-weight-bold eCPM:
              span.text-body-2 {{ formatMoney(item.avgCPM) }}
            v-flex(xs6)
              span.text-caption.font-weight-bold Spent:
              span.text-body-2 {{ formatMoney(item.spent) }}

      //---------------
      //- Mobile GEO & Environment
      //---------------
      div(v-if='currentDimensionIs(["countries", "states", "cities", "environments"])')
        v-card.ma-3.px-3.py-1
          div
            div.d-flex.mt-2(style='word-break: break-word;')
              span.d-inline-block
                v-checkbox.mt-0.pt-0(@click.stop="isSelected? select(false) : select(true)" :value="isSelected" hide-details color='primary')
              span.name {{ item.name }}
          v-layout.my-2.align-center.justify-center
            v-flex(xs6)
              v-icon.text--secondary(medium) mdi-account-multiple
              span.text-body-2.impressions  {{ item.impressions | numberWithCommas }}
            v-flex(xs6)
              v-icon.text--secondary(medium) mdi-play-circle
              span.text-body-2.adsServed  {{ item.adsServed | numberWithCommas }}

          v-layout.my-2.align-center.justify-center
            v-flex(xs6 v-if='componentConfig.campaigns.isVenuesShown')
              v-icon.text--secondary mdi-map-marker
              span.text-body-2.number-of-venues  {{ item.numberOfVenues }}
            v-flex(xs6)
              v-icon.text--secondary mdi-television
              span.text-body-2.number-of-screens  {{ item.numberOfScreens }}

          v-layout.mb-2.align-center.justify-center
            v-flex(xs6 v-if='componentConfig.campaigns.isECPMShown')
              span.text-caption.font-weight-bold.eCPM eCPM:
              span.text-body-2.avgCPM {{ formatMoney(item.avgCPM) }}
            v-flex(xs6)
              span.text-caption.font-weight-bold Spent:
              span.text-body-2.spent {{ formatMoney(item.spent) }}

</template>

<script>
import creativeImage from '@/components/creatives/creativeImage.vue'
import creativesHoverDialog from '@/components/creatives/creativesHoverDialog.vue'
import dealsHoverDialog from '@/components/dealsHoverDialog.vue'
import spentVsBudgetProgressBar from '@/components/spentVsBudgetProgressBar.vue'
import confirmationDialog from '@/components/actionConfirmationDialog.vue'
import venuePopoverDisplay from '@/components/venuePopoverDisplay'

import reportingService from '@/services/reporting.service'
import geoService from '@/services/geo.service'
import helpers from '@/services/helpers.service'
import componentConfigService from '@/services/componentConfig'

export default {
  components: {
    creativeImage,
    creativesHoverDialog,
    dealsHoverDialog,
    spentVsBudgetProgressBar,
    confirmationDialog,
    venuePopoverDisplay
  },
  props: ['instance', 'tabName', 'dimensionName', 'tableData', 'totalItems', 'tableLoading', 'search'],
  watch: {
    selected: function () {
      if (this.selected.length < this.tableData.length) { this.selectAll = false }

      if (this.selected.length) {
        this.$root.$emit('selectionFromTable', true)
      } else {
        this.$root.$emit('selectionFromTable', false)
      }
    },

    pagination: function (newVal, oldVal) {
      // bypass Table initialization
      if (!this.tableLoading) {
        if (oldVal.sortBy[0] !== newVal.sortBy[0] ||
          oldVal.sortDesc[0] !== newVal.sortDesc[0] ||
          oldVal.itemsPerPage !== newVal.itemsPerPage ||
          oldVal.page !== newVal.page ||
          oldVal.totalItems !== newVal.totalItems
        ) {
          const formattedPagination = {
            sortBy: this.pagination.sortBy[0],
            descending: this.pagination.sortDesc[0],
            rowsPerPage: this.pagination.itemsPerPage,
            page: this.pagination.page,
            totalItems: this.pagination.totalItems
          }
          this.$emit('paginationUpdated', formattedPagination)
        }
      }
    },

    tableLoading: function (newVal, oldVal) {
      if (!oldVal && newVal) {
        this.selected = []
      }
    },

    instance: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.selected = []
      }
    }
  },
  mounted () {
    this.$root.$on('clearSelection', () => {
      this.selected = []
      this.selectAll = false
    })
  },
  computed: {
    marketVals () {
      return this.$store.getters['general/marketDefaultValues']
    },
    currentDimension () {
      // fall back to "tab" name if no "dimensions" available (i.e. Creatives & Screens)
      return this.dimensionName || this.tabName
    },
    headers () {
      let headers = [...this.headersDefinitions[this.currentDimension]]

      // add a "status" column for :
      //   - Venues table when in Line report
      //   - Screens table when in Venue report
      if ((this.currentDimensionIs(['venues']) && this.currentInstanceIs('line')) || (this.currentDimensionIs(['screens']) && this.currentInstanceIs('venue'))) {
        headers.unshift({ text: 'Status', value: 'switchStatus', width: '40px', sortable: false })
      }

      // If user is from AdServer, filter any columns/headers that are to be hidden for AdServer users
      headers = headers.filter(x => !!x.isShown || x.isShown === undefined)

      return headers
    },
    headersDefinitions () {
      const componentConfig = componentConfigService(this.$store.getters['user/isForAdServer'])

      return {
        lines: [
          { text: 'Edit', value: 'editLine', width: '20px', sortable: false, class: 'edit-line' },
          { text: '', value: 'switchStatus', width: '40px', sortable: false, class: 'switch-status' },
          { text: 'Status', value: 'effectiveStatus', width: '90px', class: 'effective-status' },
          { text: 'Priority', value: 'priority', width: '90px', class: 'priority', isShown: componentConfig.campaigns.isLinePriorityShown },
          { text: 'Line Order', value: 'name', class: 'line-order' },
          { text: 'Deals', value: 'deals', width: '80px', sortable: false, class: 'deals', isShown: componentConfig.campaigns.isDealsShown },
          { text: 'Spent', value: 'spent', width: '240px', class: 'spent' },
          { text: 'Max Bid', value: 'maxbid', width: '120px', align: 'right', class: 'max-bid', isShown: !componentConfig.campaigns.isCPMFixed },
          { text: 'Fixed CPM', value: 'maxbid', width: '120px', align: 'right', class: 'fixed-CPM', isShown: componentConfig.campaigns.isCPMFixed },
          { text: 'Creatives', value: 'creatives', align: 'right', width: '105px', sortable: false, class: 'creatives' },
          { text: 'Impressions', value: 'impressions', width: '130px', align: 'right', class: 'impressions' },
          { text: 'eCPM', value: 'avgCPM', width: '80px', align: 'right', class: 'avgCPM', isShown: componentConfig.campaigns.isECPMShown }
        ],
        venues: [
          { text: 'Venue Name', value: 'name', width: '250px' },
          { text: 'Type', value: 'locationType' },
          { text: 'City', value: 'city' },
          { text: 'Screens', value: 'numberOfScreens' },
          { text: 'Spent', value: 'spent', align: 'right' },
          { text: 'Impressions', value: 'impressions', align: 'right' },
          { text: 'eCPM', value: 'avgCPM', align: 'right', isShown: componentConfig.campaigns.isECPMShown },
          { text: 'Ads Served', value: 'adsServed', align: 'right' }
        ],
        screens: [
          { text: 'Screen Name', value: 'name', width: '250px' },
          { text: 'Type', value: 'locationType' },
          { text: 'City', value: 'city' },
          { text: 'Screens', value: 'numberOfScreens' }, // numberOfScreens VS numberOfFaces
          { text: 'Spent', value: 'spent', align: 'right' },
          { text: 'Impressions', value: 'impressions', align: 'right' },
          { text: 'eCPM', value: 'avgCPM', align: 'right', class: 'avgCPM', isShown: componentConfig.campaigns.isECPMShown },
          { text: 'Ads Served', value: 'adsServed', align: 'right' }
        ],
        creatives: [
          { text: 'Preview', value: 'preview', width: '112px', sortable: false },
          { text: 'Creative', value: 'name', width: '250px' },
          { text: 'Screens', value: 'numberOfScreens' }, // numberOfScreens VS numberOfFaces
          { text: 'Spent', value: 'spent', align: 'right' },
          { text: 'Impressions', value: 'impressions', align: 'right' },
          { text: 'eCPM', value: 'avgCPM', align: 'right', isShown: componentConfig.campaigns.isECPMShown },
          { text: 'Ads Served', value: 'adsServed', align: 'right' }
        ],
        countries: [
          { text: 'Country', value: 'name', width: '250px', class: 'name' },
          { text: 'Venues', value: 'numberOfVenues', class: 'number-of-venues', isShown: componentConfig.campaigns.isVenuesShown },
          { text: 'Screens', value: 'numberOfScreens', class: 'number-of-screens' },
          { text: 'Spent', value: 'spent', align: 'right', class: 'spent' },
          { text: 'Impressions', value: 'impressions', align: 'right', class: 'impressions' },
          { text: 'eCPM', value: 'avgCPM', align: 'right', class: 'avgCPM', isShown: componentConfig.campaigns.isECPMShown },
          { text: 'Ads Served', value: 'adsServed', align: 'right', class: 'adsServed' }
        ],
        states: [
          { text: 'State', value: 'name', width: '250px', class: 'name' },
          { text: 'Venues', value: 'numberOfVenues', class: 'number-of-venues', isShown: componentConfig.campaigns.isVenuesShown },
          { text: 'Screens', value: 'numberOfScreens', class: 'number-of-screens' },
          { text: 'Spent', value: 'spent', align: 'right', class: 'spent' },
          { text: 'Impressions', value: 'impressions', align: 'right', class: 'impressions' },
          { text: 'eCPM', value: 'avgCPM', align: 'right', class: 'avgCPM', isShown: componentConfig.campaigns.isECPMShown },
          { text: 'Ads Served', value: 'adsServed', align: 'right', class: 'adsServed' }
        ],
        cities: [
          { text: 'City', value: 'name', width: '250px', class: 'name' },
          { text: 'Venues', value: 'numberOfVenues', class: 'number-of-venues', isShown: componentConfig.campaigns.isVenuesShown },
          { text: 'Screens', value: 'numberOfScreens', class: 'number-of-screens' },
          { text: 'Spent', value: 'spent', align: 'right', class: 'spent' },
          { text: 'Impressions', value: 'impressions', align: 'right', class: 'impressions' },
          { text: 'eCPM', value: 'avgCPM', align: 'right', class: 'avgCPM', isShown: componentConfig.campaigns.isECPMShown },
          { text: 'Ads Served', value: 'adsServed', align: 'right', class: 'adsServed' }
        ],
        environments: [
          { text: 'Environment', value: 'name', width: '250px' },
          { text: 'Venues', value: 'numberOfVenues' },
          { text: 'Screens', value: 'numberOfScreens' },
          { text: 'Spent', value: 'spent', align: 'right' },
          { text: 'Impressions', value: 'impressions', align: 'right' },
          { text: 'eCPM', value: 'avgCPM', align: 'right', isShown: componentConfig.campaigns.isECPMShown },
          { text: 'Ads Served', value: 'adsServed', align: 'right' }
        ]
      }
    },
    campaignId () {
      return this.$route.params.campaignId
    },
    lineId () {
      return this.$route.params.lineId
    },
    venueId () {
      return this.$route.params.venueId
    },
    noDataText () {
      return this.tableLoading ? 'Loading...' : 'No data available'
    },
    campaignData () {
      return this.$store.getters['campaignReport/getCampaign']
    },
    lineData () {
      return this.$store.getters['campaignReport/getLine']
    },
    account () {
      return this.$store.getters['campaignReport/getAccount']
    },
    campaignAssignments () {
      return this.$store.getters['campaignReport/getAssignments']
    },
    assignmentsLoading () {
      return this.$store.getters['campaignReport/getCampaignAssignmentsLoading']
    },
    troubleshootingToolVisible () {
      return this.$store.getters['general/troubleshootingToolVisible']
    }
  },
  methods: {
    formatMoney (val) {
      return helpers.formatMoney(val, 2, this.marketVals.currencySymbolString)
    },
    currentInstanceIs (instanceName) {
      return this.instance === instanceName
    },
    currentTabIs (tabName) {
      return this.tabName === tabName
    },
    currentDimensionIs (dimensionNames) {
      return dimensionNames.indexOf(this.currentDimension) > -1
    },
    // ---------------
    // - LINES
    // ---------------
    lineStatusLabel (line) {
      return reportingService.lineStatusLabel(line)
    },
    lineStatusColor (line) {
      return reportingService.lineStatusColor(line)
    },
    lineStatusIcon (line) {
      return reportingService.lineStatusIcon(line)
    },
    lineStatusTooltip (line) {
      return reportingService.lineStatusTooltip(line)
    },
    lineStatusChanged (row) {
      this.$emit('lineStatusChanged', {
        id: row.id,
        bookingStatus: row.bookingStatus === 'Active' ? 'Paused' : 'Active'
      })
    },
    lineAssignments (lineId) {
      return this.campaignAssignments.filter(a => a.lineId === lineId)
    },
    editLine (changedFieldsObj, toDelete) {
      this.$emit('lineUpdated', changedFieldsObj, toDelete)
    },
    // ---------------
    // - VENUES
    // ---------------
    venueStatus (row) {
      if (this.lineData && row) {
        return reportingService.getStatusFromGeography(this.lineData.geography, 'venue', row.key, this.marketVals.distanceUnit)
      } else {
        return true
      }
    },
    venueAddress (row) {
      var address = row.streetNumber + ', ' + row.street
      if (row.suite) {
        address += ' #' + row.suite
      }
      return address
    },
    venueStatusChanged (row) {
      var completedRow = Object.assign({}, row, { id: parseInt(row.key) })

      const toStatus = this.venueStatus(row) ? 'exclude' : 'include'
      const geo = geoService.reconstructGeography(this.lineData.geography, completedRow, { toStatus: toStatus, type: 'venue' }, this.marketVals.distanceUnit)
      this.$emit('venueStatusChanged', { id: this.lineData.id, geography: geo })
    },
    venuePopoverInfos (row) {
      var extra = {
        id: parseInt(row.key),
        environment: row.locationType,
        address: this.venueAddress(row)
      }

      return Object.assign({}, row, extra)
    },
    // ---------------
    // - SCREENS
    // ---------------
    screenStatus (row) {
      if (this.lineData && row) {
        return reportingService.getStatusFromGeography(this.lineData.geography, 'screen', row.key, this.marketVals.distanceUnit)
      } else {
        return true
      }
    },
    screenStatusChanged (row) {
      const toStatus = this.screenStatus(row) ? 'exclude' : 'include'
      const rowWithId = { ...row }
      rowWithId.id = row.key
      const geo = geoService.reconstructGeography(this.lineData.geography, rowWithId, { toStatus: toStatus, type: 'screen' }, this.marketVals.distanceUnit)
      this.$emit('screenStatusChanged', { id: this.lineData.id, geography: geo })
    },

    exportCsvBtnClicked () {
      let selectedElems = []
      if (this.selected.length && !this.selectAll) {
        selectedElems = this.selected.map(x => parseInt(x.key))
      }
      this.$emit('exportCsvBtnClicked', selectedElems)
    },

    assembleActionToolbarText (nbr, dimension, addThe = false) {
      // Builds the sentence similar to "3 campaigns selected"
      let adjustedInstance = null
      if (nbr > 1) {
        adjustedInstance = dimension
      } else {
        if (dimension === 'countries') {
          adjustedInstance = 'country'
        } else if (dimension === 'cities') {
          adjustedInstance = 'city'
        } else {
          adjustedInstance = dimension.slice(0, dimension.length - 1)
        }
      }
      const prefix = addThe ? 'The ' : ''
      return `${prefix}${nbr} ${adjustedInstance} selected`
    },

    duplicate ({ keepCreatives }) {
      this.$emit('duplicateLines', { selected: this.selected, keepCreatives })
      this.selected = []
      this.selectAll = false
    },

    archive () {
      const options = [...this.selected].map(x => { return { id: x.id, bookingStatus: 'Archived' } })
      this.$emit('archiveLines', options)
      this.selected = []
      this.selectAll = false
    },

    openEditLineDialog (line, src = 'budget') {
      this.$emit('openEditLineDialog', { src, line })
    },

    openTroubleshootingTool (line) {
      this.$emit('openTroubleshootingTool', line)
    }
  },
  data () {
    return {
      items: [],
      selected: [],
      selectAll: false,
      componentConfig: componentConfigService(this.$store.getters['user/isForAdServer']),
      supportDefinitions: [
        {
          tid: '18',
          name: 'Ad Served',
          description: 'A single play of an ad creative. <a href="https://www.campsiteproject.com/blog/2016/11/18/dooh-metrics-and-traffic-estimation-explained" target="_blank">Learn more</a>.\n',
          parent_tid: '294',
          weight: '4',
          crm_key: 'ad-served'
        },
        {
          tid: '330',
          name: 'Impression',
          description: '<p>An impression is someone exposed to a creative.</p>\n\n<strong>For outdoor screens, we use circulation numbers</strong><br />\nOutdoor advertising circulation is calculated as the number of people aged 5+ with a reasonable opportunity to see an advertising <strong>screen </strong>during a 24-hour day.\n',
          parent_tid: '294',
          weight: '0',
          crm_key: 'impression'
        }
      ],
      pagination: {
        sortBy: ['impressions'],
        sortDesc: [true],
        itemsPerPage: 150,
        page: 1,
        totalItems: 0
      },
      isForAdServer: this.$store.getters['user/isForAdServer']
    }
  }
}
</script>
