export default { getDefaultValuesByCurrency, getAllDefaultValues }

const canadianValues = Object.freeze({
  id: 1,
  mapCenter: [-94.5, 49.5],
  mapZoom: 3,
  mapZoomProposalLine: 2.6,
  currency: 'CAD',
  countryId: 39,
  countryCode: 'CA',
  stateLabel: 'Province',
  postcodeLabel: 'Postal Code',
  countryName: 'Canada',
  lineName: 'Canada',
  distanceUnit: 'metric',
  currencySymbolString: '$',
  timezone: 'America/Toronto',
  geos: 'Examples: Alberta, Montreal, -94.5, 49.5, H2W 2R2, 301 Front St W, Toronto, Needle Vinyl Tavern',
  canSeeScreens: true,
  canSeeMobileTargeting: true,
  canSeeAudienceDemographics: true,
  isPublic: true,
  temperatureScale: 'celsius',
  market: 'indoor',
  label: 'Canada',
  displayOrder: 2
})

const usValues = Object.freeze({
  id: 4,
  mapCenter: [-95.5, 37],
  mapZoom: 3,
  mapZoomProposalLine: 2.6,
  currency: 'USD',
  countryId: 240,
  countryCode: 'US',
  stateLabel: 'State',
  postcodeLabel: 'Zip Code',
  countryName: 'United States',
  lineName: 'United States',
  distanceUnit: 'imperial',
  currencySymbolString: '$',
  timezone: 'America/New_York',
  geos: 'Examples: California, Boston, -95.5, 44, 90210, 5th avenue NY, Times Square',
  canSeeScreens: true,
  canSeeMobileTargeting: true,
  canSeeAudienceDemographics: true,
  isPublic: true,
  temperatureScale: 'fahrenheit',
  market: 'united-state',
  label: 'United States Of America',
  displayOrder: 1
})

const australianValues = Object.freeze({
  id: 7,
  mapCenter: [133.7751, -25.2744],
  mapZoom: 3,
  mapZoomProposalLine: 2.6,
  currency: 'AUD',
  countryId: 14,
  countryCode: 'AU',
  stateLabel: 'State',
  postcodeLabel: 'Postcode',
  countryName: 'Australia',
  lineName: 'Australia',
  distanceUnit: 'metric',
  currencySymbolString: '$',
  timezone: 'Australia/Sydney',
  geos: 'Examples: Queensland, Melbourne, -37.79, 144.96, 6032, Collins street Melbourne, Sydney Opera House',
  canSeeScreens: true,
  canSeeMobileTargeting: true,
  canSeeAudienceDemographics: true,
  isPublic: true,
  temperatureScale: 'celsius',
  market: 'australia',
  label: 'Australia',
  displayOrder: 3
})

const europeanValues = Object.freeze({
  id: 8,
  mapCenter: [10.2946, 50.0833],
  mapZoom: 3,
  mapZoomProposalLine: 2.6,
  currency: 'EUR',
  countryId: null,
  countryCode: 'EU',
  stateLabel: 'State',
  postcodeLabel: 'Postal Code',
  countryName: 'Europe',
  lineName: 'Europe',
  distanceUnit: 'metric',
  currencySymbolString: '€',
  timezone: 'Europe/London',
  geos: 'Examples: London, 52.37, 4.90, 08025, Rue La Fayette, Louvre Museum',
  canSeeScreens: true,
  canSeeMobileTargeting: false,
  canSeeAudienceDemographics: true,
  isPublic: false,
  temperatureScale: 'celsius',
  market: 'europe',
  label: 'Europe',
  displayOrder: 4
})

const globalValues = Object.freeze({
  id: 9,
  mapCenter: [10, 40],
  mapZoom: 1.15,
  mapZoomProposalLine: 0.8,
  currency: 'USD',
  countryId: null,
  countryCode: 'GL',
  stateLabel: 'State',
  postcodeLabel: 'Postal Code',
  countryName: 'Rest of the World',
  lineName: 'Rest of the World',
  distanceUnit: 'metric',
  currencySymbolString: '$',
  timezone: 'UTC',
  geos: 'Examples: London, 52.37, 4.90, 08025, Rue La Fayette, Louvre Museum',
  canSeeScreens: true,
  canSeeMobileTargeting: false,
  canSeeAudienceDemographics: false,
  isPublic: false,
  temperatureScale: 'celsius',
  market: 'global',
  label: 'Rest of the World',
  displayOrder: 5
})

const defaultValuesByCurrencyCode = {
  CAD: canadianValues,
  USD: usValues,
  AUD: australianValues,
  EUR: europeanValues
}

function getDefaultValuesByCurrency (currency) {
  return defaultValuesByCurrencyCode[currency]
}

function getAllDefaultValues () {
  return [
    canadianValues,
    usValues,
    australianValues,
    europeanValues,
    globalValues
  ]
}
