import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import axios from 'axios'
import VueAxios from 'vue-axios'
import setupAxios from './services/axios.service'

import moment from 'moment'
import VueMomentJS from 'vue-momentjs'
import vueHeadful from 'vue-headful'

import accounting from 'accounting-js'
import Scrollspy from 'vue2-scrollspy'

import './styles/style.sass'
import './plugins/datadog'

import Rox from './plugins/rox/rox'
import flags from './plugins/rox/flags'

import { Auth0Plugin } from './plugins/auth0'
import SubdomainService from '@/services/subdomain.service'

Vue.use(VueAxios, axios)
Vue.use(VueMomentJS, moment)
Vue.component('vue-headful', vueHeadful)
Vue.use(Scrollspy)

const flagsPlugin = Vue.observable({ loaded: false })
Vue.prototype.$flagsPlugin = flagsPlugin
Vue.use(Rox, { flags, apiKey: process.env.VUE_APP_CLOUDBEES_KEY })

Vue.use(Auth0Plugin, {
  audience: 'https://platform.broadsign.com/',
  domain: process.env.VUE_APP_AUTH0_URL,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  redirectUri: window.location.origin
})

SubdomainService.setAuthProvider()
function setTitle () {
  if (document.title !== '') {
    return
  }
  if (SubdomainService.isAdServerDomain()) {
    document.title = 'Broadsign'
  } else {
    document.title = 'Broadsign Ads'
  }
}
setTitle()

setupAxios(store, router)

/**
 * Filters
 */
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('date', function (date, format = 'MMM Do, YYYY') {
  if (!date) return ''
  return format === 'calendar' ? moment(date).calendar() : moment(date).format(format)
})

Vue.filter('currency', function (value) {
  const marketVals = store.getters['general/marketDefaultValues']
  if (value === null) return ''
  return accounting.formatMoney(value, { symbol: marketVals.currencySymbolString, precision: 2 })
})

Vue.filter('numberWithCommas', function (value, precision = 0) {
  if (!value) return '0'
  return accounting.formatNumber(value, { precision })
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created () {
    this.$store.dispatch('general/updateRoutePath', this.$route.path)
    this.$router.afterEach((to) => {
      this.$store.dispatch('general/updateRoutePath', to.path)
    })
  }
}).$mount('#app')
