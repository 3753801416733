import { render, staticRenderFns } from "./organizationTheme.vue?vue&type=template&id=592936ec&scoped=true&lang=pug"
import script from "./organizationTheme.vue?vue&type=script&lang=js"
export * from "./organizationTheme.vue?vue&type=script&lang=js"
import style0 from "./organizationTheme.vue?vue&type=style&index=0&id=592936ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "592936ec",
  null
  
)

export default component.exports