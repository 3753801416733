<template lang="pug">
v-card(:loading='loading')
  v-form(ref='userForm' v-model='userFormValidated')

    v-card-title.text-h5(v-if='!loading && can.read.allUsers && !userToBeEdited && !organizationId')
      .d-inline-block.mr-2 Invite user to
      v-autocomplete.d-inline-block(
        v-model='orgInvitedTo'
        placeholder='Organization Name'
        :disabled='!orgs.length'
        :items='orgs'
        item-text='name'
        return-object
        :rules='[rules.required]'
        style='max-width: 250px'
        autocomplete='nope'
        )
      .d-inline-block.ml-2 organization.

    v-card-title.text-h5(v-if='!userToBeEdited && organizationId')
      | {{ title }}

    v-card-text
      v-container.px-0(fluid)
        v-row.my-0(justify='center')
          v-col.py-0#profile-field-wrapper-first-name(cols='12' sm='6')
            v-text-field#profile-field-first-name(
              label='First Name'
              v-model='formData.firstName'
              counter='50'
              :rules='[rules.required, rules.counter(50)]'
            )
          v-col.py-0#profile-field-wrapper-last-name(cols='12' sm='6')
            v-text-field#profile-field-last-name(
              label='Last Name'
              v-model='formData.lastName'
              counter='70'
              :rules='[rules.required, rules.counter(70)]'
            )
          v-col.py-0(cols='12')
            v-text-field#profile-field-email(label='Email' v-model='formData.email' :disabled='isEmailDisabled' :rules='[rules.required, rules.email]')
          v-col.py-0(cols='12')
            v-select.roles-dropdown(
              v-if='can.read.allUsers'
              multiple
              label='Role(s)'
              :disabled='!orgInvitedToTermsAccepted'
              :items='rolesAvailable.list'
              :rules='[rules.roleRequired]'
              item-text='name'
              return-object
              v-model='formData.roles')
            .text-caption.error--text.invite-owner-only(v-if='can.read.allUsers && !orgInvitedToTermsAccepted')
              b Terms &amp; Conditions
              |  not yet accepted by this Organization, so can only invite Owner

            v-radio-group#profile-field-roles(v-if='!can.read.allUsers && formData.role !== -1' v-model='formData.role' :rules='[rules.notNull]')
              template(v-slot:label='')
                div Role
              v-radio(
                class='profile-field-role'
                color='primary'
                v-for='role in rolesAvailable.list'
                :key='role.id'
                :value='role.id'
                :label='role.name'
                :disabled='rolesAvailable.disabled'
                )

            userRolePermissions.mt-3

    v-card-actions.pa-5
      v-spacer
      v-btn.px-4#profile-btn-submit(
        rounded
        color='primary'
        :block='$vuetify.breakpoint.xsOnly'
        :disabled='!userFormValidated'
        :loading='udpateBtnLoading'
        @click='updateUser()'
        ) {{ userToBeEdited ? 'save' : 'invite user'}}
</template>

<script>
import userRolePermissions from '@/components/user/userRolePermissions.vue'

import userApi from '@/services/user.api'
import helpersService from '@/services/helpers.service'
import tracking from '@/services/tracking'

export default {
  components: {
    userRolePermissions
  },
  props: ['userToBeEdited', 'organizationId', 'loading', 'isDialogOpen'],
  data () {
    return {
      rules: {
        required: value => !!value || 'Required',
        notNull: value => value !== null || 'Required',
        roleRequired: list => { return list.length > 0 || 'Select at least one Role' },
        email: v => /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(v) || 'E-mail must be valid',
        counter (maxValue) {
          return value => (value && value.length <= maxValue) || 'Too long.'
        }
      },
      udpateBtnLoading: false,
      orgs: [],
      roles: [],
      dspRoleIdsHierarchy: [3, 8, 2, 1],
      orgInvitedTo: null,
      orgInvitedToTermsAccepted: true,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        role: null,
        roles: []
      },
      userFormValidated: false
    }
  },
  created () {
    this.initialize()
  },
  watch: {
    userToBeEdited (newVal, oldVal) {
      this.prefillForm()
    },
    orgInvitedTo (newVal, oldVal) {
      if (newVal) {
        this.orgInvitedToTermsAccepted = newVal.termsVersion === this.$store.getters['user/getAppTermsVersion'] || newVal.dspExternalId != null

        // can only invite Owner if Terms & Conditions not yet accepted by Organization
        if (!this.orgInvitedToTermsAccepted) {
          this.formData.roles = this.roles.filter(role => role.name === 'Owner')
        } else {
          if (!this.organizationId) {
            this.formData.roles = []
          }
        }
      }
    },
    isDialogOpen (newV) {
      if (!newV) this.resetForm()
      else this.initialize()
    }
  },
  computed: {
    currentUserProfile () {
      return this.$store.getters['user/getProfile']
    },
    can () {
      return this.$store.getters['user/permissions']('user')
    },
    rolesAvailable () {
      if (this.can.read.allUsers) {
        return {
          list: [...this.roles].sort(helpersService.sortByName),
          disabled: false
        }
      } else {
        return {
          list: [...this.roles].filter(x => this.dspRoleIdsHierarchy.includes(x.id)).sort(this.sortByHierarchy),
          disabled: this.userToBeEdited && this.userToBeEdited.id === this.currentUserProfile.id
        }
      }
    },
    isEmailDisabled () {
      return !!this.userToBeEdited
    },
    title () {
      return this.orgInvitedTo
        ? 'Invite user to ' + this.orgInvitedTo.name
        : 'Invite user'
    }
  },
  methods: {
    initialize () {
      if (this.userToBeEdited) {
        this.prefillForm()
      }

      var organizationsFilters = []
      if (this.organizationId) {
        organizationsFilters.push('id eq ' + this.organizationId)
      }
      userApi.getOrganizationsAdv({ sort: 'name asc', filters: organizationsFilters })
        .then(orgs => {
          this.orgs = orgs

          if (this.organizationId) {
            this.orgInvitedTo = orgs[0]
          }
        })
        .catch(() => {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: 'Could not load Organizations'
          })
        })

      userApi.getAssignableRoles()
        .then(roles => {
          this.roles = roles
        })
        .catch(() => {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: 'Could not load Roles'
          })
        })
    },
    sortByHierarchy (a, b) {
      const aIndex = this.dspRoleIdsHierarchy.findIndex(r => r === a.id)
      const bIndex = this.dspRoleIdsHierarchy.findIndex(r => r === b.id)
      if (aIndex < bIndex) { return -1 }
      if (aIndex > bIndex) { return 1 }
      return 0
    },

    prefillForm () {
      if (this.userToBeEdited) {
        this.formData.firstName = this.userToBeEdited.firstName
        this.formData.lastName = this.userToBeEdited.lastName
        this.formData.email = this.userToBeEdited.email

        var orgId = this.organizationId || this.userToBeEdited.organizationId
        var currentOrg = this.userToBeEdited.organizations.find(o => o.id === orgId)
        if (currentOrg && currentOrg.roles.length) {
          this.formData.roles = [...currentOrg.roles]
          this.formData.role = currentOrg.roles[0].id
        }
      } else {
        this.resetForm()
      }
    },

    resetForm () {
      this.formData = {
        firstName: '',
        lastName: '',
        email: '',
        role: null,
        roles: []
      }
      this.$refs.userForm.reset()
    },

    updateUser () {
      this.udpateBtnLoading = true

      // Role(s)
      var roles = []
      if (this.can.read.allUsers) {
        roles = [...this.formData.roles]
      } else {
        var role = this.roles.find(r => r.id === this.formData.role)
        roles.push(role)
      }

      // (1) E D I T
      if (this.userToBeEdited && parseInt(this.userToBeEdited.id)) {
        // Editable fields
        const { firstName, lastName } = this.formData
        const editUser = Object.assign({ id: this.userToBeEdited.id }, { firstName, lastName })

        userApi.editUser(editUser)
          .then(editedUser => {
            if (editedUser) {
              var orgId = this.organizationId || this.userToBeEdited.organizationId
              var currentOrg = this.userToBeEdited.organizations.find(o => o.id === orgId)
              var currentRoles = []
              if (currentOrg) {
                currentRoles = currentOrg.roles
              }

              // new Role(s)
              const newRolesPromises = roles
                .filter(role => {
                  // add only if new
                  return currentRoles.find(currentRole => currentRole.id === role.id) === undefined
                })
                .map(role => {
                  const roleToAssign = {
                    roleId: role.id,
                    userId: this.userToBeEdited.id,
                    organizationId: orgId
                  }

                  return userApi.assignRole(roleToAssign)
                })

              // removed Role(s)
              const removedRolesPromises = currentRoles
                .filter(currentRole => {
                  // delete only if removed
                  return roles.find(role => role.id === currentRole.id) === undefined
                })
                .map(currentRole => {
                  const roleToRemove = {
                    roleId: currentRole.id,
                    userId: this.userToBeEdited.id,
                    organizationId: orgId
                  }

                  return userApi.removeRole(roleToRemove)
                })

              const allRolesPromises = [...newRolesPromises, ...removedRolesPromises]
              Promise.all(allRolesPromises)
                .then(rolesResp => {
                  // update Store if editing own Profile
                  if (this.userToBeEdited.id === this.currentUserProfile.id) {
                    this.$store.commit('user/updateProfile', editUser)
                  }

                  // Notify upon success
                  this.$store.commit('snackbar/setSnackbar', {
                    type: 'success',
                    msg: 'Successfully updated'
                  })

                  this.$emit('userUpdated', editUser)
                })
                .catch(e => {
                  this.handleErrors(e, 'An error occured while updating user roles')
                })
            }
          })
          .catch(e => {
            this.handleErrors(e, 'An error occured while updating user')
          })
          .finally(() => {
            this.udpateBtnLoading = false
          })
      } else {
        // (2) A D D
        const orgId = this.can.read.allUsers ? this.orgInvitedTo.id : this.currentUserProfile.organizationId

        // build Invitation
        const invitation = {
          organizationId: orgId,
          email: this.formData.email,
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          message: this.currentUserProfile.firstName + ' ' + this.currentUserProfile.lastName + ' has added you to their organization in Broadsign Ads : \n\r<br>'
        }

        // Invite user
        userApi.inviteUser(invitation)
          .then(newUser => {
            if (newUser) {
              var promises = roles.map(role => {
                const roleToAssign = {
                  roleId: role.id,
                  userId: newUser.id,
                  organizationId: orgId
                }

                return userApi.assignRole(roleToAssign)
                  .then(assignedRole => assignedRole)
              })

              Promise.all(promises).then(assignedRoles => {
                this.udpateBtnLoading = false

                // Notify upon success
                this.$store.commit('snackbar/setSnackbar', {
                  type: 'success',
                  msg: 'Successfully added'
                })

                tracking.sendEvent(['ga'], 'invitedUser')

                this.$emit('userUpdated', newUser)
              })
            }
          })
          .catch(error => {
            this.handleErrors(error, 'An error occured while processing your invitation')
            this.udpateBtnLoading = false
          })
      }
    },
    handleErrors (error, defaultMsg) {
      var msg = defaultMsg || 'An error occured'

      // use API error message when available
      if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length) {
        msg = error.response.data.errors[0].message
      }

      this.$store.commit('snackbar/setSnackbar', {
        type: 'error',
        msg
      })
    }
  }
}
</script>
