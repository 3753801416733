<template lang="pug">
v-expansion-panel(ref='linePanel' @change='expandLine')
  v-expansion-panel-header(
    :hide-actions='$vuetify.breakpoint.xsOnly'
    :class="{'px-3': $vuetify.breakpoint.xsOnly}"
    )
    template(v-slot:default='{ open }')
      v-row.my-0(no-gutters style="width: 100%;")
        v-col.pr-2(cols=11 md=7)
          .text-h6.text-truncate#name {{ line.name }}
          .text-body-2.d-flex.d-md-none.align-baseline
            span
              .d-inline-block.mr-4
                span.budget {{ budget }}
            span
              .d-inline-block.mr-4
                v-icon.mb-1() mdi-account-multiple
                span.estimated-impressions(v-if='componentConfig.campaigns.impressions.useEstimated')  {{ estimatedImpressions }}
                span.targeted-impressions(v-else)  {{ targetedImpressions }}
        v-col.text-h5.pr-4.text-right.d-none.d-sm-flex.justify-end(md='4')
          v-fade-transition.d-inline(leave-absolute)
            span(v-if='open' key='0')
            span(v-else='' key='1')
              div
                v-tooltip(top dark color='secondary' max-width='300')
                  template(v-slot:activator='{on}')
                    span.mx-2(v-on='on')
                      .d-inline-block.mr-4
                        span.budget {{ budget }}
                  .text-caption.text-left
                    | Proposed Budget
                v-tooltip(top dark color='secondary' max-width='300')
                  template(v-slot:activator='{on}')
                    span.mx-2(v-on='on')
                      .d-inline-block.mr-4
                        v-icon.mb-1() mdi-account-multiple
                        span.estimated-impressions(v-if='componentConfig.campaigns.impressions.useEstimated')  {{ estimatedImpressions }}
                        span.targeted-impressions(v-else)  {{ targetedImpressions }}
                  .text-caption.text-left
                    span(v-if='componentConfig.campaigns.impressions.useEstimated') Estimated Budgeted Impressions
                    span(v-else) Target Impressions
        v-col.text-right.justify-end.align-center.d-sm-flex(cols=1 v-if='!isPublic || canUpdate' :class="{'pr-4': $vuetify.breakpoint.smAndUp}")
          v-menu(offset-y left)
            template(v-slot:activator='{ on }')
              v-btn.proposal-line-menu-activator(small icon v-on='on' @click.stop='')
                v-icon mdi-dots-vertical
            v-list.proposal-line-menu(dense)
              v-list-item#edit-line(@click="openEditLineDialog('budget', line)")
                v-list-item-icon.mr-4
                  v-icon mdi-pencil
                v-list-item-title Edit
              v-list-item#duplicate-line(@click='duplicateLine')
                v-list-item-icon.mr-4
                  v-icon mdi-content-copy
                v-list-item-title Duplicate
              v-divider(v-if='troubleshootingToolVisible')
              v-list-item#troubleshoot-line(v-if='troubleshootingToolVisible' @click='troubleshootLine(line)')
                v-list-item-icon.mr-4
                  v-icon mdi-wrench
                v-list-item-title Troubleshoot
              v-divider
              v-list-item#archive-line(@click='deleteLine')
                v-list-item-icon.mr-4
                  v-icon mdi-delete
                v-list-item-title Remove

      v-dialog(v-model='isConfirmDialogOpen' width='400' style='height: inherit;')
        v-card
          v-card-title.text-h5.text-capitalize {{ confirmDialogActionLabel | capitalize }} Line
          v-card-text Are you sure you want to {{ confirmDialogActionLabel }} selected Line?
            span(v-if='confirmDialogActionLabel === "remove"') This action cannot be undone.
          v-divider
          v-card-actions
            v-spacer
            v-btn(text @click='confirmDialogCancel') Cancel
            v-btn(text color='primary' @click='confirmDialogConfirm' :loading='confirmDialogConfirmed') {{ confirmDialogActionLabel | capitalize }}

  component(
    :is="$vuetify.breakpoint.smAndUp? 'v-expansion-panel-content' : 'v-dialog'"
    :fullscreen='$vuetify.breakpoint.xsOnly'
    v-model="$vuetify.breakpoint.xsOnly? openLineDialog : undefined"
    transition='dialog-bottom-transition'
    ref='linePanelContent'
    )
    v-card(:tile='$vuetify.breakpoint.xsOnly' elevation=0 :style="$vuetify.breakpoint.xsOnly? 'padding-top: 48px !important;' : ''")
      v-app-bar(dark color='primary' fixed dense v-if='$vuetify.breakpoint.xsOnly && openLineDialog')
        v-toolbar-title.text-truncate(style='max-width: 80%;') {{ line.name }}
        v-spacer
        v-btn(
          icon
          @click="$emit('closedDialog', panelIndex) ; openLineDialog = false"
          )
          v-icon mdi-close

      v-row.my-0(no-gutters)
        v-scroll-y-transition
          v-btn.primary--text.mx-auto.mb-4(
            style='position: fixed; bottom:0; left: 0; right: 0; z-index: 1;'
            color='white'
            width=95
            rounded
            @click='showMapOnly = !showMapOnly'
            v-if='$vuetify.breakpoint.xsOnly && tab === 1'
            elevation=12
            )
            v-icon.mr-2(color='primary') {{ showMapOnly? 'mdi-menu' : 'mdi-map-outline' }}
            | {{ showMapOnly? 'list' : 'map' }}

        v-col(cols='12' md='6')
          v-tabs(v-model='tab' @change='changeTab' :grow='$vuetify.breakpoint.xsOnly')
            v-tab(key='1')
              | Info
            v-tab(key='2')
              | Inventory
            v-tab#deals-tab(key='3' v-if='canSeePrivateDealTab')
              | Private Deals
            v-tab#spec-tab(key='4')
              | Specs
          v-divider

          v-tabs-items(v-model='tab' @change='changeTab' touchless)
            v-tab-item(key='1' :style="$vuetify.breakpoint.xsOnly? '' : 'height: 541px; overflow-y: auto;'")
              div.pl-6.pr-6.pb-0.pt-0(
                :style="$vuetify.breakpoint.xsOnly? 'overflow:auto;-webkit-overflow-scrolling:touch' : ''"
                )
                v-row.my-0
                  v-col.pb-0(cols=12 md=7)
                    div
                      .text-overline Schedule
                      .text-h6.font-weight-regular#schedule-dates {{ dates }}
                      .text-caption.grey--text#schedule-dayparting {{ dayparting }}
                  v-col.pb-0(cols=12 md=5)
                    div(v-if='componentConfig.campaigns.impressions.useEstimated')
                      .text-overline Est. Impressions
                      .text-h6.font-weight-regular.estimated-impressions
                        v-icon.mb-1.mr-1() mdi-account-multiple
                        | {{ estimatedImpressions }}
                    div(v-else)
                      .text-overline Target Impressions
                      .text-h6.font-weight-regular.targeted-impressions {{ targetedImpressions }}

                  v-col(cols=12)
                    v-row.my-0
                      v-col.py-0(cols=12 md=7 :order="$vuetify.breakpoint.smAndDown? 2 : 1" v-if='canSeeImpressionDistribution && componentConfig.campaigns.impressions.isDistributionShown')
                        div
                          .text-overline Est. Impressions Distribution
                          impressionsDistribution.my-2(
                              :environments='environments'
                              :initialized="!details.isLoadingForecast"
                              :taxonomy='envsTaxonomy'
                              :loading="details.isLoadingForecast"
                              style='height: 100px; width: 100%;'
                            )
                      v-col.py-0(cols=12 md=5 :order="$vuetify.breakpoint.smAndDown? 1 : 2")
                        v-row.my-0
                          v-col.pt-0(cols=6 md=12 v-if="componentConfig.campaigns.isGoalsShown")
                            div
                              .text-overline Goal Type
                              .text-h6.font-weight-regular.goal-type {{ goalType }}
                          v-col.pt-0(cols=6 md=12 v-if="componentConfig.campaigns.isLinePriorityShown && priorityName")
                            div
                              .text-overline Line priority
                              .text-h6.font-weight-regular.line-priority {{ priorityName }}
                          v-col.pt-0(cols=6 md=12)
                            div
                              .text-overline Budget
                              .text-h6.font-weight-regular {{ budget }}
                          v-col.pt-0(cols=6 md=12)
                            div(v-if="componentConfig.campaigns.isCPMFixed")
                              .text-overline CPM
                              .text-h6.font-weight-regular.fixed-cpm-price {{ maxBid }}
                            div(v-else-if="!line.deals.length || line.usePublicExchange")
                              .text-overline.bid-range-label Price range in CPM
                              .text-h6.font-weight-regular.bid-range {{ bidRange }}
                              .text-caption.grey--text.max-bid Max Bid: {{ maxBid }} CPM

                            v-tooltip(top dark color='secondary' max-width=300
                              v-else-if="$store.getters['auth/isLoggedIn'] && line.deals.length"
                              :value='dealsTooltipOpened')
                              template(v-slot:activator='{on}')
                                div(v-on="on")
                                  div.d-flex
                                    .text-overline Net Max Bid
                                    v-icon.ml-1(small color='grey') mdi-information
                                  .text-h6.font-weight-regular {{ maxBid }} CPM

                              .text-caption.grey--text.d-block.fees-disclaimer(v-if="line.deals.length && markup")
                                //- | Deal CPM is what the publisher will be paid.
                                //- br
                                | Standard costs and fees apply. All included max bid CPM is
                                strong  {{ line.maxCpm * (1 + markup / 100 ) | currency }}
                                |.

                v-row.my-0
                  v-col.pt-0(cols='12')
                    div.mb-2.targeting-summary.location(v-if='visibleSections.location')
                      .text-overline Location
                      .one-line-truncate
                        .text-body-2.text-overflow-hidden(v-for='item in info.location.listToDisplay') {{ item }}
                      v-dialog(v-if='info.location.hidden.length > 0' max-width=550 :fullscreen='$vuetify.breakpoint.xsOnly' v-model='isDialogOpen')
                        template(v-slot:activator='{on}')
                          .primary--text.text-caption(v-on='on' style='cursor: pointer;') {{ `+ ${info.location.hidden.length} more` }}
                        v-card(:tile='$vuetify.breakpoint.xsOnly')
                          v-card-title
                            | Location
                            v-spacer
                            v-btn(icon @click='isDialogOpen = false')
                              v-icon mdi-close
                          v-list-item-group(color='primary')
                            v-list-item.px-6(
                              v-for='(item, i) in [...info.location.listToDisplay, ...info.location.hidden]'
                              :key='i' dense)
                              | {{ item }}
                    div.mb-2.targeting-summary.environment(v-if='visibleSections.environment')
                      .text-overline Environment
                      .two-lines-truncate.text-body-2.text-overflow-hidden {{ info.environment }}
                    div.mb-2.targeting-summary.demographic(v-if="visibleSections.demographic")
                      .text-overline Demographic
                      .two-lines-truncate.text-body-2.text-overflow-hidden {{ info.demographic }}
                    div.mb-2.targeting-summary.screen(v-if="visibleSections.screen")
                      div.mb-2(v-for="item in info.screen" :key='item.key')
                        .text-overline.screen-targeting-type {{ item.key }}
                        .one-line-truncate.text-body-2.text-overflow-hidden.screen-targeting-value {{ item.value }}

                    moments-read.targeting-summary.moments(
                      v-if='line.momentId'
                      :temperature-scale='temperatureScale'
                      :moment-id='line.momentId'
                      :postEvent='momentsPostEvent'
                      :authToken="$store.getters['user/getToken']")

            v-tab-item(key='2')
              InventoryMap(
                v-if='showMapOnly && $vuetify.breakpoint.xsOnly'
                height='calc(100vh - 96px)'
                :bound-initial-forecast='componentConfig.campaigns.boundInitialForecastCall'
                compact-popups
                :default-latitude='marketVals.mapCenter[1]'
                :default-longitude='marketVals.mapCenter[0]'
                :default-zoom='marketVals.mapZoom'
                :forecast-inventory='forecastInventoryTargetedPositions'
                :forecast-inventory-loading='details.isLoadingForecastInventory'
                :geo-targets='geoTargets'
                :get-venue-details-func='getVenueDetails'
                :map-box-token='mapboxToken'
                :map-id="'mapForLine' + line.id"
                readonly
                :ref='"mapComponent" + line.id'
                :show-fullscreen-button='false'
                :show-impressions='false'
                :show-inventory-summary='false'
                :show-venue-popup='true'
                :unit='marketVals.distanceUnit'
                :resolve-geo-json-func='resolveGeoJson'
                :disable-recentering='disableRecentering'
                @mapBoundsUpdated='mapBoundsUpdated'
              )
              div(v-show='!showMapOnly || $vuetify.breakpoint.smAndUp')
                forecast-inventory(
                  :loading='details.isLoadingVenues'
                  :downloadingInventory='downloadingInventory'
                  :items='details.venues'
                  :total-items='targetedVenues'
                  :show-total-items='true'
                  :has-more-items='details.pagination.hasMorePages'
                  :page='details.pagination.page'
                  defaultTableHeight=461
                  updateVenueInclusionEnabled=false
                  @inventoryListChanged='updateTableToMapSync'
                  @downloadInventory='downloadInventory')

            v-tab-item(key='3' v-if='canSeePrivateDealTab')
              inventoryDealsTable(
                :deals='line.deals.map(d => d.deal)'
                :dealsLoading='isLoading')

            v-tab-item(key='4')
              forecast-specs(:required-formats='requiredFormats' :show-assigned='showAssigned')

        v-col.d-sm-flex(cols=12 md=6 v-if='$vuetify.breakpoint.smAndUp')
          InventoryMap(
            height='590px'
            style='width: 100%; height: 100%; z-index: 0;'
            :bound-initial-forecast='componentConfig.campaigns.boundInitialForecastCall'
            :default-latitude='marketVals.mapCenter[1]'
            :default-longitude='marketVals.mapCenter[0]'
            :default-zoom='marketVals.mapZoomProposalLine'
            :filter-on-move='filterOnMove'
            :forecast-inventory='forecastInventoryTargetedPositions'
            :forecast-inventory-loading='details.isLoadingForecastInventory'
            :forecast-loading='details.isLoadingForecast'
            :geo-targets='geoTargets'
            :get-venue-details-func='getVenueDetails'
            :map-box-token='mapboxToken'
            :map-id="'mapForLine' + line.id"
            readonly
            :ref='"mapComponent" + line.id'
            :show-details-button='true'
            :show-fullscreen-button='true'
            :show-impressions='false'
            :show-venue-popup='true'
            :targeted-venues='targetedVenues'
            :targeted-screens='targetedScreens'
            :unit='marketVals.distanceUnit'
            :zoom-on-updated-forecast='details.pagination.filtering'
            :resolve-geo-json-func='resolveGeoJson'
            :disable-recentering='disableRecentering'
            @filterOnMoveChecked='filterOnMoveChecked'
            @mapBoundsUpdated='mapBoundsUpdated'
            @hideDetails='hideDetails'
            @seeDetails='seeDetails'
          )
            template(v-slot:fullscreen-drawer-content='')
              forecast-inventory(
                :loading='details.isLoadingVenues'
                :downloadingInventory='downloadingInventory'
                :items='details.venues'
                :total-items='targetedVenues'
                :show-total-items='true'
                :has-more-items='details.pagination.hasMorePages'
                :page='details.pagination.page'
                updateVenueInclusionEnabled=true
                @inventoryListChanged='updateTableToMapSync'
                @downloadInventory='downloadInventory')
</template>

<script>
import forecastSpecs from '@/components/forecast.specs.vue'
import forecastInventory from '@/components/forecast.inventory.vue'
import impressionsDistribution from '@/components/impressionsDistribution.vue'
import venuePopoverDisplay from '@/components/venuePopoverDisplay'
import creativeFooterInfos from '@/components/creatives/creativeFooterInfos'
import venueThumbnail from '@/components/venueThumbnail'
import inventoryDealsTable from '@/components/inventoryDealsTable'

import audienceAPI from '@/services/audience.api'
import venuesAPI from '@/services/venues.api'

import csvService from '@/services/csv.service'
import defaultExchangeValues from '@/services/defaultExchangeValues'
import helpers from '@/services/helpers.service'
import audienceService from '@/services/audience.service'
import geoService from '@/services/geo.service'
import { mapState, mapGetters } from 'vuex'

import componentConfigService from '@/services/componentConfig'
import campsiteConfig from '@/config/campsite.config'
import defaultCurrencyValues from '@/services/defaultCurrencyValues'

// dynamic Vuetify components used with <component :is="v-component"> must be registered locally
import { VDialog, VExpansionPanelContent } from 'vuetify/lib'

import momentsRead from '@/web-components/moments/momentsRead.vue'
import { InventoryMap } from '@ayudasystems/campaign-targeting'

import _ from 'lodash'
import geoBoundingBoxService from '@/services/geo.boundingbox.service'

export default {
  components: {
    forecastSpecs,
    forecastInventory,
    venuePopoverDisplay,
    creativeFooterInfos,
    venueThumbnail,
    VDialog,
    VExpansionPanelContent,
    impressionsDistribution,
    momentsRead,
    InventoryMap,
    inventoryDealsTable
  },
  props: ['line', 'isPublic', 'panelIndex', 'canUpdate', 'isDspPartnerProposal'],
  created () {
    this.geoTargetsWithGeoJson = this.selectedGeoTargets
  },
  data () {
    return {
      isDialogOpen: false,
      tab: null,
      openLineDialog: false,
      showMapOnly: false,
      canSeeCurrencySymbol: this.isDspPartnerProposal,
      // confirmation dialog
      isConfirmDialogOpen: false,
      confirmDialogConfirmed: false,
      confirmDialogActionLabel: null,
      confirmDialogActionCallback: null,

      downloadingInventory: false,
      momentsPostEvent: null,
      dealsTooltipOpened: false,
      secondsInACreative: campsiteConfig.creatives.defaultDuration.image,

      mapboxToken: process.env.VUE_APP_MAPBOX_TOKEN,
      isFilterOnMoveVisible: false,
      disableRecentering: false,
      updateGeoTargetsJsonCT: null,
      geoTargetsWithGeoJson: [],
      mapViewPort: {
        minLatitude: 0,
        minLongitude: 0,
        maxLatitude: 0,
        maxLongitude: 0,
        zoom: 3
      }
    }
  },
  mounted () {
    this.$root.$on('refreshWeatherMoment', (lineId) => {
      if (lineId === this.line.id) {
        this.momentsPostEvent = { name: 'refresh moment' }
      }
    })
  },
  computed: {
    ...mapState('proposals', {
      isLoading: state => state.isLoadingProposal,
      proposal: state => state.proposal,
      linesDetails: state => state.details,
      buyerMarkup: state => state.buyerMarkup
    }),
    ...mapState('audience', {
      initialTargeting: state => state.initialTargeting
    }),
    ...mapGetters('proposals', [
      'proposedBySentence'
    ]),
    marketVals () {
      const markets = defaultExchangeValues.getAllDefaultValues()
      return markets.find(market => market.id === this.line.exchangeId)
    },
    showAssigned () {
      return !(this.isDspPartnerProposal || this.isPublic)
    },
    temperatureScale () {
      return this.marketVals.temperatureScale
    },
    dates () {
      return helpers.readableDateDuration(this.line.startDate, this.line.endDate)
    },
    dayparting () {
      return audienceService.formatSchedule({ audienceSegments: this.line.targeting })
    },
    goalType () {
      return 'Impression Based'
    },
    priorityName () {
      return this.line.priority?.name
    },
    lineCurrency () {
      return this.canSeeCurrencySymbol
        ? defaultCurrencyValues(this.line.currency?.code ?? 'USD').currencySymbolString
        : this.marketVals.currencySymbolString
    },
    budget () {
      return helpers.formatMoney(this.line.budget, 0, this.lineCurrency)
    },
    maxBid () {
      return helpers.formatMoney(this.line.maxCpm, 2, this.lineCurrency)
    },
    markup () {
      return this.buyerMarkup
        ? ((this.buyerMarkup - 1) * 100).toFixed(2)
        : null
    },
    bidRange () {
      if (this.details.isLoadingForecast) return ''

      const bidRange = this.details.forecasts.dealBidRange
      const min = bidRange ? bidRange.minCpmps : null
      const max = bidRange ? bidRange.maxCpmps : null
      if (!min || !max) return 'N/A'
      else {
        const minFormattedMoney = helpers.formatMoney(min * this.secondsInACreative, 2, this.lineCurrency)
        const maxFormattedMoney = helpers.formatMoney(max * this.secondsInACreative, 2, this.lineCurrency)
        return minFormattedMoney === maxFormattedMoney
          ? minFormattedMoney
          : `${minFormattedMoney} - ${maxFormattedMoney}`
      }
    },
    info () {
      let tg = []
      let t = []
      let taxo
      const exchanges = this.$store.getters['general/getExchanges']
      const targetGroups = this.$store.getters['general/allTargetGroups']
      const targets = this.$store.getters['general/allTargets']
      const taxonomies = this.$store.getters['general/allTaxonomies']
      const i = exchanges.findIndex(x => x.id === this.line.exchangeId)
      const distanceUnit = this.marketVals.distanceUnit
      if (i > -1 && Object.keys(targetGroups).length) {
        tg = targetGroups[exchanges[i].key]
        t = targets[exchanges[i].key]
        taxo = taxonomies[exchanges[i].key]
      }
      return {
        location: audienceService.formatGeoTargetsFull(this.line.geography, 2, distanceUnit),
        demographic: audienceService.formatMobileTargets(this.line.targeting, tg, this.lineCurrency),
        environment: audienceService.formatEnvironments(this.line.segments, taxo),
        screen: audienceService.formatScreenIntoKeyValueList(
          this.line.targeting,
          this.initialTargeting,
          t
        )
      }
    },
    visibleSections () {
      return {
        location: !!this.info.location,
        environment: !!this.info.environment,
        demographic: this.marketVals.canSeeMobileTargeting && !!this.info.demographic,
        screen: this.marketVals.canSeeScreens && !!this.info.screen
      }
    },
    details () {
      return this.linesDetails[this.line.id.toString()]
    },
    estimatedImpressions () {
      const forecastImpressions = this.details.forecasts?.budget?.impressions?.value

      const imps = forecastImpressions ? { from: forecastImpressions, to: forecastImpressions } : this.line?.budgeted?.impressions

      if (!this.line.budgeted || !this.line.budget || (this.line.deals.length && this.isForAdServer) || this.line.momentId || !imps) return 'N/A'
      if (imps.from === imps.to) return helpers.shortenNumber(imps.from)
      return ['from', 'to'].map(k => helpers.shortenNumber(imps[k])).join(' - ')
    },
    targetedImpressions () {
      return helpers.shortenNumber((this.line.budget / this.line.maxCpm) * 1000)
    },
    canSeeImpressionDistribution () {
      return this.line.budget && parseFloat(this.line.budget) !== 0 && (!this.line.deals.length || this.line.usePublicExchange) && !this.line.momentId
    },
    targetedVenues () {
      return this.details.forecasts.inventory.numberOfVenues
    },
    targetedScreens () {
      return this.details.forecasts.inventory.numberOfScreens
    },
    requiredFormats () {
      return this.details.forecasts.formats
    },
    envsTaxonomy () {
      let taxo = null
      const exchanges = this.$store.getters['general/getExchanges']
      const taxonomies = this.$store.getters['general/allTaxonomies']
      const i = exchanges.findIndex(x => x.id === this.line.exchangeId)
      if (i > -1 && Object.keys(taxonomies).length) taxo = taxonomies[exchanges[i].key]
      return taxo
    },
    environments () {
      return this.line.budget
        ? this.details.forecasts?.budget?.environments
        : this.details.forecasts?.environments
    },
    selectedGeoTargets () {
      const distanceUnit = this.marketVals.distanceUnit
      return this.line.geography ? geoService.geoFromApiFormatting(this.line.geography, distanceUnit) : []
    },
    forecastInventoryTargetedPositions () {
      const positions = this.details.forecastInventory.positions
        .filter(x => x.target.size > 0)
        .map(x => {
          const copy = JSON.parse(JSON.stringify(x))
          if (x.target.size < x.size) copy.size = x.target.size
          return copy
        })

      return { positions }
    },
    troubleshootingToolVisible () {
      return this.$store.getters['general/troubleshootingToolVisible']
    },
    componentConfig () {
      return componentConfigService(this.isForAdServer)
    },
    isForAdServer () {
      return this.proposal?.isForAdServer || false
    },
    geoTargets () {
      return this.geoTargetsWithGeoJson
        .filter(geoTarget => geoTarget.type !== 'point_of_interest')
        .map(this.convertGeoTargetToMapFormat)
    },
    filterOnMove () {
      return {
        isVisible: this.isFilterOnMoveVisible,
        value: this.details.filterAsMapMoves
      }
    },
    canSeePrivateDealTab () {
      return this.$store.getters['auth/isLoggedIn'] && this.line.deals.length && this.componentConfig.campaigns.isDealsShown
    }
  },
  watch: {
    line (newVal) {
      if (newVal) {
        const exchanges = this.$store.getters['general/getExchanges']
        this.exchangeFromLine = exchanges.find(x => x.id === this.line.exchangeId)
      }
    },
    async selectedGeoTargets () {
      await this.updateTargetsGeoJson()
    }
  },
  methods: {
    expandLine () {
      if (this.details.isLoadingForecast) {
        this.loadLine()
      }
      this.openLineDialog = true
    },
    loadLine () {
      if (!this.line.geoLoaded) {
        this.$store.dispatch('proposals/getLine', this.line.id)
          .then(lineWithGeo => {
            this.$store.dispatch('proposals/getLineForecast', this.line.id)
          })
      } else {
        this.$store.dispatch('proposals/getLineForecast', this.line.id)
      }
    },
    mapBoundsUpdated: _.debounce(async function (mapApiPoco) {
      if (this.details.isLoading) return

      const mapSettings = {
        lineId: this.line.id,
        mapSettings: mapApiPoco
      }
      this.$store.commit('proposals/updateLineMapSettings', mapSettings)
      this.mapViewPort = this.getMapViewPort(mapApiPoco)

      await this.updateTargetsGeoJson(true)

      const pagination = {}
      if (this.details.filterAsMapMoves) { pagination.page = 1 }
      if (this.details.pagination.filtering) { pagination.filtering = false }
      if (Object.keys(pagination).length) {
        this.$store.dispatch('proposals/updatePagination', { lineId: this.line.id, pagination })
      }

      this.$store.dispatch('proposals/loadInventory', {
        source: 'map',
        lineId: this.line.id,
        mobileMapOnly: this.$vuetify.breakpoint.xsOnly && this.showMapOnly
      })
    }, 300),
    hideDetails () {
      this.isFilterOnMoveVisible = false
    },
    seeDetails () {
      this.tab = 1
      this.changeTab()
    },
    filterOnMoveChecked () {
      this.$store.commit('proposals/updateMapTableSync', this.line.id)
    },
    updateTableToMapSync (pagination) {
      // check if "filtering" BEFORE changing value in Store
      pagination.filtering = this.details.pagination.query !== pagination.query
      this.$store.dispatch('proposals/updatePagination', { lineId: this.line.id, pagination })
      this.$store.dispatch('proposals/loadInventory', { source: 'table', lineId: this.line.id })
    },

    changeTab () {
      this.isFilterOnMoveVisible = this.tab === 1

      if (this.tab === 1) {
        this.$store.dispatch('proposals/fetchVenues', this.line.id)
      }

      const lineActiveTab = {
        lineId: this.line.id,
        tab: this.tab
      }
      this.$store.commit('proposals/setLineActiveTab', lineActiveTab)
    },

    confirmDialogConfirm () {
      this.confirmDialogConfirmed = true
      this[this.confirmDialogActionCallback]()
    },

    confirmDialogCancel () {
      this.isConfirmDialogOpen = false
      this.confirmDialogConfirmed = false
      this.confirmDialogActionLabel = null
      this.confirmDialogActionCallback = null
    },

    duplicateLine (options) {
      // request confirmation
      if (!this.confirmDialogConfirmed) {
        this.confirmDialogActionLabel = 'duplicate'
        this.confirmDialogActionCallback = 'duplicateLine'
        this.isConfirmDialogOpen = true
      } else {
        var errorMsg = 'Something went wrong while duplicating Line.'

        this.$store.dispatch('proposals/duplicateProposalLine', this.line.id)
          .then(duplicatedLine => {
            if (duplicatedLine) {
              const msg = 'Line ' + duplicatedLine.name + ' successfully created'
              this.$store.commit('snackbar/setSnackbar', {
                type: 'success',
                msg: msg
              })
            } else {
              this.$store.commit('snackbar/setSnackbar', {
                type: 'error',
                msg: errorMsg
              })
            }
          })
          .catch(error => {
            if (error.response && error.response.data.errors[0].message) {
              errorMsg = error.response.data.errors[0].message
            }

            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: errorMsg
            })
          })
          .finally(() => {
            this.confirmDialogCancel()
          })
      }
    },

    deleteLine () {
      // request confirmation
      if (!this.confirmDialogConfirmed) {
        this.confirmDialogActionLabel = 'remove'
        this.confirmDialogActionCallback = 'deleteLine'
        this.isConfirmDialogOpen = true
      } else {
        this.$store.dispatch('proposals/removeLineFromProposal', this.line.id)
          .then(removed => {
            if (removed) {
              const msg = 'Line ' + this.line.name + ' successfully removed'
              this.$store.commit('snackbar/setSnackbar', {
                type: 'success',
                msg: msg
              })
            } else {
              this.$store.commit('snackbar/setSnackbar', {
                type: 'error',
                msg: 'Something went wrong while removing Line.'
              })
            }
          })
          .catch(error => {
            const msg = error.response.data.errors[0].message
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: `${msg}`
            })
          })
          .finally(() => {
            this.confirmDialogCancel()
          })
      }
    },
    openEditLineDialog (src, line) {
      // "geography" not returned by /lines anymore,
      // so "line" prop won't have it attached
      // query API to get it before opening Dialog,
      // cause Dialog will look for "geoLoaded" flag on Line and if not present,
      // it will use getLine() from "campaignReport" store to get "geography", but Proposals use their own store
      if (!this.line.geoLoaded) {
        this.$store.dispatch('proposals/getLine', this.line.id)
          .then(lineWithGeo => {
            this.$emit('openEditLineDialog', { src, line: lineWithGeo })
          })
      } else {
        this.$emit('openEditLineDialog', { src, line: this.line })
      }
    },
    downloadInventory (scope) {
      this.downloadingInventory = true

      const options = this.details.commonForecastOptions
      audienceAPI.getListTargetedInventory(options, scope)
        .then(res => {
          const name = this.line.name
          csvService.validateAndExportCsv(res, name)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.downloadingInventory = false
        })
    },
    troubleshootLine (line) {
      this.$emit('openTroubleshootingTool', line)
    },
    getVenueDetails (venueId) {
      return venuesAPI.getVenueDetails(venueId)
    },
    async resolveGeoJson (geographyId, geoJSONFileUrl) {
      return await geoService.resolveGeoJson(geographyId, geoJSONFileUrl)
    },
    convertGeoTargetToMapFormat (geoTarget) {
      return {
        isIncluded: geoTarget.isIncluded,
        radius: geoTarget.radius,
        isUnrendered: geoTarget.isOutsideOfViewPort,
        geolocation: {
          label: geoTarget.label,
          value: geoTarget.value,
          type: geoTarget.type,
          geography: {
            latitude: geoTarget.geography?.latitude,
            longitude: geoTarget.geography?.longitude,
            geoJSON: geoTarget.geography?.geoJSON
          }
        }
      }
    },
    async updateTargetsGeoJson (disableRecentering = false) {
      if (this.updateGeoTargetsJsonCT) {
        this.updateGeoTargetsJsonCT.cancel('Request canceled by a newer request.')
        this.updateGeoTargetsJsonCT = null
      }

      const { getUpdatedGeoTargets, cancelTokenSource } = geoBoundingBoxService.getGeoTargetsJsonUpdater(this.selectedGeoTargets, this.mapViewPort, disableRecentering)
      if (!getUpdatedGeoTargets) return

      this.updateGeoTargetsJsonCT = cancelTokenSource
      try {
        this.geoTargetsWithGeoJson = await getUpdatedGeoTargets()
        this.disableRecentering = disableRecentering
      } catch (e) {} finally {
        this.updateGeoTargetsJsonCT = null
      }
    },
    getMapViewPort (mapSettings) {
      return {
        minLatitude: mapSettings.min.latitude,
        minLongitude: mapSettings.min.longitude,
        maxLatitude: mapSettings.max.latitude,
        maxLongitude: mapSettings.max.longitude,
        zoom: mapSettings.zoom
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> .v-expansion-panel-content__wrap {
  padding:0px;
}

>>> .v-data-table__progress .v-progress-linear {
  position: absolute
}

>>> .v-dialog.v-dialog--fullscreen {
  background-color: #f3f3f3;
}
</style>
